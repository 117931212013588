import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import beautify from "xml-beautifier";
declare const copyToClipboard: any;
declare var $
import { COMMA, ENTER, V, X } from "@angular/cdk/keycodes";
import { SelectionModel } from '@angular/cdk/collections';
import { AppConfigService } from '../configurations/app-config.service';
import { ToastrService } from 'ngx-toastr';
import { async } from '@angular/core/testing';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { Observable, empty, of } from 'rxjs';
import { data } from 'highcharts';
import { DataValidationComponent } from 'src/app/Model/data-validation/data-validation.component';
import { mergeMap } from 'rxjs/operators';
import { MatChipInputEvent } from "@angular/material/chips";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FileViewExplorerComponent } from '../file-view-explorer/file-view-explorer.component';
import { AngularEditorComponent } from '@kolkov/angular-editor';
import { ConsolidateviewfileComponent } from 'src/app/consolidateviewfile/consolidateviewfile.component';
import { CompareComponent } from '../compare/compare.component';

import { Hl7viewerComponent } from '../hl7viewer/hl7viewer.component';
@Component({
  selector: "app-hubmessages",
  templateUrl: "./hubmessages.component.html",
  styleUrls: ["./hubmessages.component.scss"],
})
export class HubmessagesComponent implements OnInit {
  panelOpenState;
  messageFullData = [];
  msgfieldlist: any = [];

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild("msgfieldInput", { static: false }) msgfieldInput: ElementRef<HTMLInputElement>;
  @ViewChild("fileContent", { static: false }) fileContent: any;
  msgfieldctrl = new FormControl("");
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Output() hl7MessageEvent = new EventEmitter<string>();
  @Input() messageData;
  @Input() case360;
  @Output() case360search = new EventEmitter<string>();
  archivedbcheck: boolean = false;
  usernamedisplay: string;
  isLoadingroutes: boolean = false;
  isLoadingTarget: boolean = false;
  isLoadingProcesor: boolean = false;
  isLoadingsource: boolean = false;
  cardHeight: Number = window.innerHeight - 275;
  stagemodalheight: Number = window.innerHeight - 52;
  stagemodalheighttextarea: Number = window.innerHeight - 183;
  isfocus: boolean = false;
  msgfieldplaceholder: string =
    "Paste multiple values (max 5) separated by | character";
  tablediv: boolean = false;
  statusmessageMainflg: boolean = false;
  keywordsearch = "displayname";
  showDropdown: boolean = false;
  RouteNameList: any = [];
  SourceAccountList: any = [];
  TargetAccountList = <any>[];
  ProcessorList = <any>[];
  messageList = <any>[];
  messagestageList = <any>[];
  messageTypeList = <any>[];

  selectedmessagetype: string = "";
  selectedrouteId: string = "";
  selectedrouteDisplayName: string = "";

  selectedsourceaccountid: string = "";
  selectedsourceaccountName: string = "";
  selectedtargetaccountid = "";
  selectedtargetdeployementkey = "";
  selectedtargetassociatedaccountids = "";
  selectedtargetaccountName = "";

  selectedprocessagentid = "";
  selectedprocessdisplayname = "";
  deploymentkey: string = "";
  selectedsourceassociatedaccountids: string = "";

  reactiveFormtarget: FormGroup;
  reactiveFormprocess: FormGroup;
  reactiveFormsource: FormGroup;
  reactiveFormroute: FormGroup;

  reactiveFormmessageid: FormGroup;
  reactiveFortransactionid: FormGroup;
  reactiveForentityfield: FormGroup;
  reactiveFormentity: FormGroup;
  selectedmessagestatustype: string = "ALL";

  reactiveFormmsgtype: FormGroup;
  reactiveFormmsgstatus: FormGroup;
  isAddedForReprocess: boolean;
  screenHeight: number;
  notfounddata: boolean;
  message_iot_data: any = {};
  arhivecheckbackup: boolean;
  fileidarray: any[] = [];

  logIdList = <any>[];
  caseIdList = <any>[];
  handlerList = <any>[];
  statusMirth = <any>[];
  createdDate = <any>[];
  processingDate = <any>[];

  MirthFitlerForm: FormGroup;

  isLoadinghandler: boolean;
  isLoadingcaseid: boolean;
  isLoadinglogid: boolean;
  isLoadingstatusMirth: boolean;
  isLoadingCreatedDate: boolean;
  isLoadingProcessingDate: boolean;
  isadvancefilter: boolean;

  keywordsearchcaseid = "caseid";
  keywordsearchhandler = "handler";
  keywordsearchLogID = "LogId";
  keywordsearchstatusmirth = "status";
  keywordsearchcreatedDate = "createddate";
  keywordsearchprocessingDate = "processingdate";

  notfounddata1: boolean;
  notfounddata2: boolean;
  notfounddata3: boolean;
  notfounddata4: boolean;
  notfounddata5: boolean;

  SuccessMessageList: any[] = [];
  CreatedMessageList: any[] = [];
  failureMessageList: any[] = [];
  AutohealingMessageList: any[] = [];
  ProcessingMessageList: any[] = [];
  IgnoreMessageList: any[] = [];
  OtherMessageList: any[] = [];

  isContainsMultiplefileid: boolean;
  messageiotreprocess: boolean;
  downloadbuttonenable: boolean = true;
  backupinputdata: any;
  msgObjforiot: any;
  today = new Date();
  isEngine: any = "HUB";
  isFormView: boolean;
  selectedIndexTab: any = 0;
  mirthStatusList = [
    { displayname: "All" },
    { displayname: "New" },
    { displayname: "Success" },
    { displayname: "Failure" },
    { displayname: "Processing" },
    { displayname: "Ignore" },
    { displayname: "Others" },
  ];
  selecteditemscount: any;
  showSourceTarget: boolean = true;
  isLoadingentity: boolean = false;
  EntityNameList = <any>[];
  selectedEntityguid: string = "";
  selectedEntityName: string = "";
  messageMirthList: any[];
  messagestageMirthList: any[];
  totalmessagecountMirth: any;
  invalidlogID: boolean;
  invalidcaseid: boolean;
  isvalidSPLchar: boolean;
  reactiveFormparentmessageid: FormGroup;
  backupcase360message: any;
  pagenumber: number = 1;
  PageSize = 100;
  pageEvent: PageEvent;

  selectedsourcetypeName: string = "";
  selectedsourcedeploymentkey: string = "";
  selectedtargettypeName: string = "";
  selectedtargetdeploymentkey: string = "";
  selectedEntityType: string = "";
  selectedEntityDeploymentkey: string = "";

  messageNote: string = "";
  messageSelector = <any>[];
  messageindenty: string = "";

  backupfileArray: any[] = [];
  dataExtractRouteName: string = "";
  CsvDataExtract = <any>[];
  copyinput: string =
    '<Case AccessionNumber="DM24-000031" CaseComments="" CaseGUID="4117054A-F54F-414D-B7E4-303B439308DB" CaseProfileName="" CaseRejectionNotes="" CaseType="GI Histology_PLUS" CaseTypeDisplayName="GI Histology" CaseXMLVersion="2.1" CopyReport="0" DemographyStatus="Completed" DisclaimerValue="" Encounter="" Fasting="" GleasonScore="" HighRiskCase="false" InternalNotes="[ Cybill Guregyan, 01/06/24 - 10:16 ] &lt;span style=&quot;font-family: Roboto;&quot;&gt;Audited&lt;/span&gt;" IsAbnormal="" IsRepeated="" IsTestCase="0" LmpDate="" ObservationAndQuantitationComments="" OrderCode="GIHist" OrderNo="" ProcessingDate="" ProcessingNotes="" ResultStatus="Final" Ruleouts="" SendOut="No" SendOutDate="" SendOutNotes="" SendOutServices="" SendOutWork="Not Applicable" Services="Technical Only" SpecialInstructions="special indications/rule out: microscopic colitis, gastritis/H. pylori" Stage="Diagnosis" Status="Finalized" TechKitLotNumber="" VisitNumber="" clienttrackingnumber="" orderno="">';
  constructor(
    private router: Router,
    private service: CommonService,
    private _fb: FormBuilder,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
    private datePipe: DatePipe
  ) {
    this.getProcessorName("pipeline");
    this.reactiveFormsource = _fb.group({ name: "" });
    this.reactiveFormroute = _fb.group({ routename: "" });
    this.reactiveFormtarget = _fb.group({ namet: "" });
    this.reactiveFormprocess = _fb.group({ namep: "" });
    this.reactiveFormmessageid = _fb.group({ namemessageid: "" });
    this.reactiveFortransactionid = _fb.group({ nametransactionid: "" });
    this.reactiveForentityfield = _fb.group({ nameentityfieldid: "" });
    this.reactiveFormmsgtype = this._fb.group({ msgname: "" });
    this.reactiveFormmsgstatus = this._fb.group({ msgstatusname: "" });
    this.reactiveFormentity = this._fb.group({ entityname: "" });
    this.reactiveFormparentmessageid = this._fb.group({ parentmsgid: "" });
  }
  userrole: string = "";
  pagerolepermisions: any = [];
  isSearched: boolean = false;
  inputdata: any = {};
  inputhl7data: any
  ngOnChanges() {
    this.messageList = this.messageData;
    if (this.case360 != null || this.case360 != undefined) {
      if (this.case360.routeid !== undefined) {
        this.selectedrouteId = this.case360.routeid;
        this.transactionidvalue = this.case360.transaction;
        this.SearchMessages(false);
      } else {
        this.rolepermission();
        this.messageList = JSON.parse(
          JSON.stringify(this.case360.hubmessagedata)
        );
        this.getMessagestatuslist("msgstatus");
      }
    }
  }
  showallcaseinboundmessage() {
    this.isSearched = true;
    this.isEngine = "HUB";
    this.messageFullData = this.messageStatusAllList;
    this.messageFullData.forEach(ele => {
      ele.selector = new SelectionModel<any>(true, []);
    });
    this.backupcase360message = JSON.parse(
      JSON.stringify(this.case360.hubmessagedata)
    );
    this.messageFullData[this.selectedIndexTab].messageList = JSON.parse(
      JSON.stringify(this.case360.hubmessagedata)
    );
    this.messageFullData[
      this.selectedIndexTab
    ].messagestageList = this.case360.hubmessagestage;
    this.messageFullData[
      this.selectedIndexTab
    ].totalmessagecount = this.case360.hubmessagedata.length;
  }

  async ngOnInit() {
    this.setDateFilter()
    this.inputdata = JSON.parse(sessionStorage.getItem('hubmessagedata'));
    if (this.inputdata != '' && this.inputdata != null) {
      sessionStorage.removeItem('hubmessagedata');
      this.selectedrouteId = this.inputdata['id'];
      this.selectedrouteId != '' ? this.getRouteNames(this.selectedrouteId) : '';
      if (this.inputdata['status']) {
        this.selectedmessagestatustype = this.inputdata['status'];

        this.reactiveFormmsgstatus.patchValue({
          msgstatusname: this.selectedmessagestatustype
        });
        this.fromdate = this.datePipe.transform(this.getPreviousDay(new Date()), 'yyyy-MM-dd');
        this.todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
      this.getMessagestatuslist("msgstatus");

      // this.getRouteDetails();
      setTimeout(() => {
        this.SearchMessages(false);
      }, 1000);
    }
    this.MirthFitlerForm = this._fb.group({
      handler: "",
      LogId: "",
      caseid: "",
      status: "",
      createddate: "",
      processingdate: "",
      deployment: "",
    });

    // this.messageList = this.messageData.data;
    if (this.service.messagesData !== "") {
      this.messageData = JSON.parse(this.service.messagesData);
      this.isFormView = true;
      this.isEngine = this.messageData["engine"];
      if (this.isEngine === "HUB") {
        this.reactiveFormroute.patchValue({
          routename: this.messageData["name"],
        });
        this.selectedrouteId = this.messageData["name"];
        this.SearchMessages(false);
      } else {
        this.messageFullData = JSON.parse(JSON.stringify(this.mirthStatusList));

        this.messageFullData.forEach(ele => {
          ele.selector = new SelectionModel<any>(true, []);
        });
        this.MirthFitlerForm.patchValue({
          handler: this.messageData.name,
        });
        this.messageFullData[
          this.selectedIndexTab
        ].messageList = this.messageData.MessageDetails;
        // this.messageFullData[this.selectedIndexTab].messagestageList = response.hubmessagestage as any[];
        this.messageFullData[
          this.selectedIndexTab
        ].totalmessagecount = this.messageData.MessageDetails.length;
        // this.messageStatusList = this.mirthStatusList;
        // this.messageStatusList.forEach( eleem =>{
        //   eleem['messageList'] =[];
        //   eleem['messagestageList'] =[];
        //   eleem['totalmessagecount'] =0;
        // })

        // this.messageList = this.messageData.MessageDetails
        this.isEngine = this.messageData["engine"];
        this.reactiveFormroute.patchValue({
          routename: this.messageData["name"],
        });
        return;
      }
      // this.selectedrouteDisplayName = item.displayname;
    }
    //  this.messageData.interfacename

    this.screenHeight = window.innerHeight;
    if ((await this.service.checksession()) == true) {
      this.rolepermission();
      // const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuDisplayName == 'MESSAGES').MenuId;
      // const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      // actionitems.forEach(element => {
      //   this.pagerolepermisions[element.MenuName] = true;
      // });

      this.isAddedForReprocess = false;
      //this.GetMessageTypes();
      // this.GetMessageStatus();
      this.getMessagetypelist("msgtype");
      this.reactiveFormmsgtype.patchValue(this.messageTypeAllList);
      this.getMessagestatuslist("msgstatus");
      this.reactiveFormmsgstatus.patchValue(this.messageStatusAllList);
    }
  }

  rolepermission() {
    const menuid = JSON.parse(sessionStorage.getItem("rolewithmenus"))
      .find(x => x.RoleName == sessionStorage.getItem("SelectedUserRole"))
      .menuslist.find(y => y.MenuDisplayName == "MESSAGES").MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem("rolewithmenus"))
      .find(x => x.RoleName == sessionStorage.getItem("SelectedUserRole"))
      .menuslist.filter(y => y.ParentMenuId == menuid);
    actionitems.forEach(element => {
      this.pagerolepermisions[element.MenuName] = true;
    });
  }
  ngAfterViewInit() {
    if (this.case360 != null || this.case360 != undefined) {
      this.fromdate = "";
      this.todate = "";
    }
  }
  setDateFilter() {
    var curr = new Date(); // get current date
    var first = curr.getDay(); // First day is the day of the month - the day of the week
    var last = curr.getDate(); // last day is the first day + 6
    first = last - curr.getDay();
    ////console.log(curr, first, last);
    first = last - 6;
    let firstday = new Date(curr.setDate(first));
    let lastd = new Date();
    let lastday = new Date(lastd.setDate(last));
    let firstdate, lastdate, firstmonth, lastmonth;
    firstmonth =
      firstday.getMonth() + 1 < 10
        ? "0" + (firstday.getMonth() + 1)
        : firstday.getMonth() + 1;
    lastmonth =
      lastday.getMonth() + 1 < 10
        ? "0" + (lastday.getMonth() + 1)
        : lastday.getMonth() + 1;
    firstdate =
      firstday.getDate() < 10 ? "0" + firstday.getDate() : firstday.getDate();
    lastdate =
      lastday.getDate() < 10 ? "0" + lastday.getDate() : lastday.getDate();

    this.fromdate = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    // this.todate = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    this.todate = `${lastday.getFullYear()}-${lastmonth}-${lastdate}`;

    ////console.log(this.datefilter,this.enddtfilter)
  }
  // CreatedMessageList
  FilterCreatedMessage() {
    let createmessge = this.messageList.filter(va =>
      va.status === "New" ? "progress_card" : ""
    );
    this.getmirthListData(createmessge);
  }

  isAllSelectedCreated() {
    const numSelected = this.createdSelection.selected.length;
    const numRows = this.CreatedMessageList.length;
    return numSelected === numRows;
  }

  createdSelection = new SelectionModel<any>(true, []);
  createdreprocess() {
    if (this.isEngine !== "HUB") {
      this.mirthReprocessSelected(this.createdSelection.selected, "Created");
    } else {
      this.reprocessSelected(this.createdSelection.selected, "Created", "");
    }
  }

  masterToggleCreated() {
    this.isAllSelectedCreated()
      ? this.createdSelection.clear()
      : this.CreatedMessageList.forEach(row =>
        this.createdSelection.select(row)
      );
  }
  //----------------------------------------------------------------------------

  // SuccessMessageList
  FilterSuccessMessage() {
    if (this.messageData.MessageDetails.length > 0) {
      let successlist = this.messageData.MessageDetails.filter(
        va =>
          va.status === "11" ||
          va.status.toLowerCase() === "success" ||
          va.status.toLowerCase() === "generated" ||
          va.status.toLowerCase() === "generated-warnings" ||
          va.status.toLowerCase() === "generated with warnings" ||
          va.status.toLowerCase() === "processed" ||
          va.status.toLowerCase() === "processed-warnings" ||
          va.status.toLowerCase() === "attached" ||
          va.status.toLowerCase() === "order complete" ||
          va.status.toLowerCase() === "re-directed" ||
          va.status.toLowerCase() === "requisition attached" ||
          va.status.toLowerCase() === "requisition-processed" ||
          va.status.toLowerCase() === "adt complete" ||
          va.status.toLowerCase() === "adt processed with warning"
      );
      this.getmirthListData(successlist);
    } else {
      {
        this.getmirthListData([]);
      }
    }
  }

  filterdata360(status, index) {
    this.messageFullData.forEach(ele => {
      ele.selector = new SelectionModel<any>(true, []);
    });
    switch (status) {
      case "completed":
        this.messageFullData[
          index
        ].messageList = this.backupcase360message.filter(
          va => va.status == "11"
        );
        this.messageFullData[
          index
        ].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[
          this.selectedIndexTab
        ].totalmessagecount = this.messageFullData[index].messageList.length;
        break;
      case "created":
        this.messageFullData[
          index
        ].messageList = this.backupcase360message.filter(
          va => va.status == "10"
        );
        this.messageFullData[
          index
        ].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[
          this.selectedIndexTab
        ].totalmessagecount = this.messageFullData[index].messageList.length;
        break;
      case "failed":
        this.messageFullData[
          index
        ].messageList = this.backupcase360message.filter(
          va => va.status == "12"
        );
        this.messageFullData[
          index
        ].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[
          this.selectedIndexTab
        ].totalmessagecount = this.messageFullData[index].messageList.length;

        break;
      case "autoHealing":
        this.messageFullData[
          index
        ].messageList = this.backupcase360message.filter(
          va => va.status == "15"
        );
        this.messageFullData[
          index
        ].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[
          this.selectedIndexTab
        ].totalmessagecount = this.messageFullData[index].messageList.length;

        break;
      case "resolved":
        this.messageFullData[
          index
        ].messageList = this.backupcase360message.filter(
          va => va.status == "13"
        );
        this.messageFullData[
          index
        ].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[
          this.selectedIndexTab
        ].totalmessagecount = this.messageFullData[index].messageList.length;

        break;
      case "ignored":
        this.messageFullData[
          index
        ].messageList = this.backupcase360message.filter(
          va => va.status == "14"
        );
        this.messageFullData[
          index
        ].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[
          this.selectedIndexTab
        ].totalmessagecount = this.messageFullData[index].messageList.length;

        break;
      default:
        this.messageFullData[index].messageList = this.backupcase360message;
        this.messageFullData[
          index
        ].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[
          this.selectedIndexTab
        ].totalmessagecount = this.messageFullData[index].messageList.length;

        break;
    }
  }

  isAllSelectedSuccess() {
    const numSelected = this.successSelection.selected.length;
    const numRows = this.SuccessMessageList.length;
    return numSelected === numRows;
  }

  successSelection = new SelectionModel<any>(true, []);
  successreprocess() {
    if (this.isEngine !== "HUB") {
      this.mirthReprocessSelected(this.successSelection.selected, "Success");
    } else {
      this.reprocessSelected(this.successSelection.selected, "Success", "");
    }
  }
  masterToggleSuccess() {
    this.isAllSelectedSuccess()
      ? this.successSelection.clear()
      : this.SuccessMessageList.forEach(row =>
        this.successSelection.select(row)
      );
  }
  //----------------------------------------------------------------------------

  // FailureMessageList
  FilterFailureMessage() {
    let failuremessage = this.messageData.MessageDetails.filter(
      va =>
        va.status === "12" ||
        va.status.toLowerCase() === "failed" ||
        va.status.toLowerCase() === "failure" ||
        va.status.toLowerCase() === "error" ||
        va.status.toLowerCase() === "order failed" ||
        va.status.toLowerCase() === "failed to parse" ||
        va.status.toLowerCase() === "message api failure" ||
        va.status.toLowerCase() === "requisition failed" ||
        va.status.toLowerCase() === "adt failed"
    );
    this.getmirthListData(failuremessage);
  }

  isAllSelectedFailure() {
    const numSelected = this.failureSelection.selected.length;
    const numRows = this.failureMessageList.length;
    return numSelected === numRows;
  }

  failureSelection = new SelectionModel<any>(true, []);
  failurereprocess() {
    if (this.isEngine !== "HUB") {
      this.mirthReprocessSelected(this.failureSelection.selected, "Failure");
    } else {
      this.reprocessSelected(this.failureSelection.selected, "Failure", "");
    }
  }

  masterTogglefailure() {
    this.isAllSelectedFailure()
      ? this.failureSelection.clear()
      : this.failureMessageList.forEach(row =>
        this.failureSelection.select(row)
      );
  }
  //----------------------------------------------------------------------------

  // AutoHealingMessageList
  FilterAutohealingMessage() {
    this.AutohealingMessageList = this.messageList.filter(
      va => va.status === "15"
    );
  }

  isAllSelectedAutohealing() {
    const numSelected = this.AutohealingSelection.selected.length;
    const numRows = this.AutohealingMessageList.length;
    return numSelected === numRows;
  }

  AutohealingSelection = new SelectionModel<any>(true, []);
  Autohealingreprocess() {
    if (this.isEngine !== "HUB") {
      this.mirthReprocessSelected(
        this.AutohealingSelection.selected,
        "Auto-Healing"
      );
    } else {
      this.reprocessSelected(
        this.AutohealingSelection.selected,
        "Auto-Healing",
        ""
      );
    }
  }

  masterToggleAutohealing() {
    this.isAllSelectedAutohealing()
      ? this.AutohealingSelection.clear()
      : this.AutohealingMessageList.forEach(row =>
        this.AutohealingSelection.select(row)
      );
  }
  //----------------------------------------------------------------------------

  // ProcessingMessageList
  FilterProcessingMessage() {
    let processlist = this.messageData.MessageDetails.filter(
      va =>
        va.status.toLowerCase() === "message posted" ||
        va.status.toLowerCase() === "processing" ||
        va.status.toLowerCase() === "waiting"
    );
    this.getmirthListData(processlist);
  }

  isAllSelectedProcessing() {
    const numSelected = this.ProcessingSelection.selected.length;
    const numRows = this.ProcessingMessageList.length;
    return numSelected === numRows;
  }

  ProcessingSelection = new SelectionModel<any>(true, []);
  Processingreprocess() {
    if (this.isEngine !== "HUB") {
      this.mirthReprocessSelected(
        this.ProcessingSelection.selected,
        "Processing"
      );
    } else {
      this.reprocessSelected(
        this.ProcessingSelection.selected,
        "Processing",
        ""
      );
    }
  }

  masterToggleProcessing() {
    this.isAllSelectedProcessing()
      ? this.ProcessingSelection.clear()
      : this.ProcessingMessageList.forEach(row =>
        this.ProcessingSelection.select(row)
      );
  }
  //----------------------------------------------------------------------------

  // IgnoreMessageList
  FilterIgnoreMessage() {
    let ignorelist = this.messageData.MessageDetails.filter(
      va =>
        va.status.toLowerCase() === "ignore" ||
        va.status.toLowerCase() === "ignored" ||
        va.status.toLowerCase() === "skipped" ||
        va.status.toLowerCase() === "nobarcodescanned" ||
        va.status.toLowerCase() === "requisition ignored" ||
        va.status.toLowerCase() === "adt ignored" ||
        va.status.toLowerCase() === "invalid"
    );
    this.getmirthListData(ignorelist);
  }

  isAllSelectedIgnore() {
    const numSelected = this.IgnoreSelection.selected.length;
    const numRows = this.IgnoreMessageList.length;
    return numSelected === numRows;
  }

  IgnoreSelection = new SelectionModel<any>(true, []);
  Ignorereprocess() {
    if (this.isEngine !== "HUB") {
      this.mirthReprocessSelected(this.IgnoreSelection.selected, "Ignored");
    } else {
      this.reprocessSelected(this.IgnoreSelection.selected, "Ignored", "");
    }
  }

  masterToggleIgnore() {
    this.isAllSelectedIgnore()
      ? this.IgnoreSelection.clear()
      : this.IgnoreMessageList.forEach(row => this.IgnoreSelection.select(row));
  }
  //----------------------------------------------------------------------------

  // OtherMessageList
  FilterOtherMessage() {
    let otherlist = this.messageData.MessageDetails.filter(
      va =>
        va.status.toLowerCase() === "test" ||
        va.status.toLowerCase() === "bulkfile"
    );
    this.getmirthListData(otherlist);
  }

  isAllSelectedOther() {
    const numSelected = this.OtherSelection.selected.length;
    const numRows = this.OtherMessageList.length;
    return numSelected === numRows;
  }

  OtherSelection = new SelectionModel<any>(true, []);
  Otherreprocess() {
    if (this.isEngine !== "HUB") {
      this.mirthReprocessSelected(this.OtherSelection.selected, "Others");
    } else {
      this.reprocessSelected(this.OtherSelection.selected, "Others", "");
    }
  }

  masterToggleOther() {
    this.isAllSelectedOther()
      ? this.OtherSelection.clear()
      : this.OtherMessageList.forEach(row => this.OtherSelection.select(row));
  }
  //----------------------------------------------------------------------------

  fileids: string = "";
  filename: string = "";
  selectedroutedefinitionid: string = "";
  getmessage_iot(value, modalmessageiot) {
    this.messageuniqueid = value.id;
    this.ngxUiLoaderService.start();
    this.msgObjforiot = value;
    this.service
      .Hubmessage_IOTData(this.msgObjforiot["id"],this.msgObjforiot["fileid"] ,this.arhivecheckbackup)
      .subscribe(
        data => {
          if (data) {
            this.message_iot_data = data;
            this.inputhl7data = { ...data };

            if(this.inputhl7data["InputData"]!=null)
            {
              this.inputhl7data["InputData"] = this.inputhl7data["InputData"].replaceAll("\r", "[tempData]")
              this.inputhl7data["InputData"] = this.inputhl7data["InputData"].replaceAll("\n", "[tempData1]")
            }
            
            var itemsa = this.message_iot_data["TransformedData"];
            this.message_iot_data["title"] = this.msgObjforiot["messageid"];
            if (this.message_iot_data["InputData"]) {
              if (
                this.service.IsJsonString(this.message_iot_data["InputData"])
              ) {
                this.message_iot_data["InputData"] = JSON.stringify(
                  JSON.parse(this.message_iot_data["InputData"]),
                  null,
                  2
                );
              } else if (this.IsValidXML(this.message_iot_data["InputData"])) {
                this.message_iot_data["InputData"] = beautify(
                  this.message_iot_data["InputData"]
                );
              }
            }
            if (this.message_iot_data["TransformedData"]) {
              if (
                this.service.IsJsonString(
                  this.message_iot_data["TransformedData"]
                )
              ) {
                let transformparse = JSON.parse(
                  this.message_iot_data["TransformedData"]
                );
                this.fileidarray = transformparse;
                this.message_iot_data["TransformedData"] = JSON.stringify(
                  transformparse,
                  null,
                  2
                );
              } else if (
                this.IsValidXML(this.message_iot_data["TransformedData"])
              ) {
                this.message_iot_data["TransformedData"] = beautify(
                  this.message_iot_data["TransformedData"]
                );
              }
            }
            if (
              this.service.IsJsonString(
                this.message_iot_data["TransformedData"]
              ) &&
              this.message_iot_data["TransformedData"] !== null
            ) {
              this.downloadbuttonenable = true;
            } else {
              this.downloadbuttonenable = false;
            }
            this.ngxUiLoaderService.stop();
            this.modalService
              .open(modalmessageiot, {
                windowClass: "messageiot",
                backdrop: "static",
                keyboard: false,
              })
              .result.then(result => { });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error(error.statusText, "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      );
  }
  selectedRoute(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    this.SearchMessages(false);
  }

  /* download changes start*/
  downloadfilebyFileid(isflag, modalpopup) {
    this.ngxUiLoaderService.start();
    if (this.fileidarray.length > 0) {
      this.backupfileArray = [];
      for (var i = 0; i < this.fileidarray.length; i++) {
        this.downloadcontents(
          this.fileidarray[i].fileid,
          this.fileidarray[i].filename,
          isflag,
          modalpopup
        );
      }
    } else {
      //this.downloadcontents(fileId);
    }
    //this.ngxUiLoaderService.stop();
  }

  downloadcontents(fileids, filename, isflag, modalpopup) {
    let jsondata = {
      messagetype: this.messagetypefiledownload,
      fileid: fileids,
      contextId: this.messageuniqueid,
      createdBy: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
        .userdata["userid"],
    };
    this.ngxUiLoaderService.start();
    this.service.GetFileContentByFileID(jsondata).subscribe((response: any) => {
      if (response.length > 0) {
        this.backupfileArray = response.concat(this.backupfileArray);
        if (
          this.fileidarray.length == 1 &&
          response.length == 1 &&
          isflag == "view"
        ) {
          response[0].filename = filename;
          this.viewFile(response[0]);
        } else if (
          this.fileidarray.length === this.backupfileArray.length &&
          isflag == "view"
        ) {
          this.fileidarray.forEach(element => {
            let uniq = this.backupfileArray.find(
              va => va.fileid == element.fileid
            );
            uniq.filename = element.filename;
          });
          const modalRef = this.modalService.open(ConsolidateviewfileComponent, {
            windowClass: "PopupScreen",
            backdrop: "static",
            keyboard: false,
          });
          modalRef.componentInstance.backupfileArray = this.backupfileArray;
          modalRef.result.then(() => { });
        } else if (isflag == "download") {
          response.forEach(element => {
            if (element.filecontent != null) {
              filename = filename == "" ? this.InputData["inputfilename"] : filename;
              // if (filename == "") {
              //   filename = element.fileid + element.fileformat;
              // }
              this.ngxUiLoaderService.stop();
              this.downloadFiles(element, filename);
            } else {
              this.ngxUiLoaderService.stop();
            }
          });
        }
        this.ngxUiLoaderService.stop();
      }
      this.ngxUiLoaderService.stop();
    });
  }
  selectFile(response) {
    this.viewFile(response);
  }
  downloadFiles(data: any, filename: any) {
    if (filename == null || filename == '') {
      filename = this.displaymessageid;
    }
    if (!filename.includes(".")) {
      filename = filename + "." + data.fileformat;
    }
    var decodedContent = null;
    if (data.fileformat == "pdf") {
      this.ngxUiLoaderService.start();
      decodedContent = "data:application/pdf;base64," + data.filecontent;
      const link = document.createElement("a");
      link.href = decodedContent;
      link.download = filename;
      link.click();
      this.ngxUiLoaderService.stop();
    } else if (data.fileformat !== null) {
      this.ngxUiLoaderService.start();
      decodedContent = data.filecontent;
      let contype = "application/octet-stream";
      const file = new window.Blob([decodedContent], { type: contype });
      const downloadAncher = document.createElement("a");
      const fileURL = URL.createObjectURL(file);
      downloadAncher.href = fileURL;
      downloadAncher.download = filename;
      downloadAncher.click();
      this.ngxUiLoaderService.stop();
    }
  }
  /* download changes end*/

  getRouteNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingroutes = true;
      var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.RouteNameList = data as any[];
          this.RouteNameList.map(obj => obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}`);
          this.isLoadingroutes = false;
          if (this.RouteNameList.length == 1 && this.inputdata != '') {
            this.reactiveFormroute.patchValue({
              routename: this.RouteNameList[0]['displayname']
            });
          }
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingroutes = false;
          this.notfounddata = true;
        },
      });
    }
  }
  searchRouteNameCleared(event) {
    this.isLoadingroutes = false;
    this.RouteNameList = [];
    this.selectedrouteId = "";
    this.selectedrouteDisplayName = "";
    this.notfounddata = false;
  }

  sourceCustomFilter = function (
    SourceAccountList: any[],
    query: string
  ): any[] {
    return SourceAccountList;
  };
  keywordaccount = "LocationName";

  async getSourceAccountName(event: any) {
    var valuesa = event;
    let highlightedSource;
    if (event.includes(":")) {
      let splitValues = event.split(":");
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":");
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":");
      }
    }
    if (highlightedSource != "") {
      highlightedSource = event;
    }
    this.selectedsourceaccountid = "";
    this.reactiveFormsource.patchValue({
      name: event,
    });

    if (valuesa != "" && !valuesa.endsWith(":") && !valuesa.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valuesa.replaceAll(": ", ":");
      let obj = {
        lsearch: valueToSendToApiWithOutWhiteSpace,
        LocationGUID: this.selectedtargetaccountid,
        deploymentkey: this.selectedtargetdeploymentkey,
        associatedaccountids: this.selectedtargetassociatedaccountids,
      };
      // var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueToSendToApiWithOutWhiteSpace;

      this.isLoadingsource = true;

      //let response = await this.service.getapi(createSearch);
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {
        if (response["status"] && response["statusText"]) {
          this.SourceAccountList = [];
          this.notfounddata = true;
          this.isLoadingsource = false;
          this.toastr.error("Unable to fetch Source Details", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          if (response.length > 0) {
            this.SourceAccountList = response as any[];
            this.SourceAccountList = this.service.transformAlphabetically(
              this.SourceAccountList,
              "LocationName"
            );
            this.SourceAccountList.map(
              obj =>
              (obj.LocationName =
                obj.UniqueID !== null && obj.UniqueID !== undefined
                  ? `${obj.LocationName} ${"(" +
                  obj.LocationGUID +
                  ")"} ${"(" + obj.UniqueID + ")"}`
                  : `${obj.LocationName} ${"(" + obj.LocationGUID + ")"}`)
            );
          }
          if (response.length == 0) {
            this.SourceAccountList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedsourceaccountid = "";
          this.isLoadingsource = false;
        }
      });
    } else {
      this.SourceAccountList = [];
      this.notfounddata = true;
    }
  }
  getstringRoute(value) {
    let names = value.split("(");
    value = `${names[0]} <br>(${names[1]}`;
    return value;
  }
  locationString(item, item1, item2) {
    let names = item2.split("(");
    item2 =
      names[2] !== "null)" && names[2] !== undefined
        ? `${names[0]} <br> (${names[1]} <br> (${names[2]}`
        : `${names[0]} <br> (${names[1]}`;
    return "(" + item + ")" + " " + item1 + " : " + item2;
  }

  selectedsourceagentEvent(
    item //source agent selected Event
  ) {
    this.selectedsourceaccountid = item.LocationGUID;
    this.selectedsourceaccountName = item.LocationName;
    this.selectedsourceassociatedaccountids = item.associatedaccountids;
    this.selectedsourcetypeName = item.TypeName;
    this.selectedsourcedeploymentkey = item.deploymentkey;
  }

  searchSourceNameCleared(event) {
    this.isLoadingsource = false;
    this.SourceAccountList = [];
    this.selectedsourceaccountid = "";
    this.selectedsourceaccountName = "";
    this.notfounddata = false;
    this.selectedsourcetypeName = "";
    this.selectedsourcedeploymentkey = "";
    this.selectedsourceassociatedaccountids = "";
  }

  searchTargetNameCleared(event) {
    this.isLoadingTarget = false;
    this.TargetAccountList = [];
    this.selectedtargetaccountid = "";
    this.selectedtargetaccountName = "";
    this.notfounddata = false;
    this.selectedtargettypeName = "";
    this.selectedtargetdeploymentkey = "";
    this.selectedtargetassociatedaccountids = "";
  }
  searchProcesorNameCleared(event) {
    this.isLoadingProcesor = false;
    this.ProcessorList = this.ProcessorAllList;
    this.selectedprocessagentid = "";
    this.selectedprocessdisplayname = "";
    this.notfounddata = false;
  }

  targetCustomFilter = function (
    TargetAccountList: any[],
    query: string
  ): any[] {
    return TargetAccountList;
  };

  async getTargetAccountName(event: any) {
    var valueta = event;
    let highlightedTarget;
    if (event.includes(":")) {
      let splitValues = event.split(":");
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":");
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":");
      }
    }
    if (highlightedTarget != "") {
      highlightedTarget = event;
    }
    this.selectedtargetaccountid = "";
    this.reactiveFormtarget.patchValue({
      namet: event,
    });
    if (valueta != "" && !valueta.endsWith(":") && !valueta.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valueta.replaceAll(": ", ":");
      //var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueToSendToApiWithOutWhiteSpace;
      this.isLoadingTarget = true;
      let obj = {
        lsearch: valueToSendToApiWithOutWhiteSpace,
        LocationGUID: this.selectedsourceaccountid,
        deploymentkey: this.selectedsourcedeploymentkey,
        associatedaccountids: this.selectedsourceassociatedaccountids,
      };
      // let response = await this.service.getapi(createSearch);
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {
        if (response["status"] && response["statusText"]) {
          this.TargetAccountList = [];
          this.notfounddata = true;
          this.isLoadingTarget = false;
          this.toastr.error("Unable to fetch Target Details", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          if (response.length > 0) {
            this.TargetAccountList = response as any[];
            //this.TargetAccountList=this.service.transformAlphabetically(this.TargetAccountList,'LocationName');
            this.TargetAccountList.map(
              obj =>
              (obj.LocationName =
                obj.UniqueID !== null && obj.UniqueID !== undefined
                  ? `${obj.LocationName} ${"(" +
                  obj.LocationGUID +
                  ")"} ${"(" + obj.UniqueID + ")"}`
                  : `${obj.LocationName} ${"(" + obj.LocationGUID + ")"}`)
            );
          }
          if (response.length == 0) {
            this.TargetAccountList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedtargetaccountid = "";
          this.isLoadingTarget = false;
        }
      });
    } else {
      this.TargetAccountList = [];
      this.notfounddata = true;
    }
  }
  ProcessorAllList = <any>[];
  async getProcessorName(event: any) {
    this.selectedprocessagentid = "";
    this.isLoadingProcesor = true;
    var apiname = "";
    if (event !== "pipeline") {
      apiname =
        "api/Agents/GetByDisplayName?name=" +
        event +
        "&agentid=0&type=Processor&filterflag=true";
      this.ProcessorList = this.ProcessorAllList.filter(t =>
        t.displayname.toLowerCase().includes(event.toLowerCase()) || t.id === event);
      if (this.ProcessorList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingProcesor = false;
    } else {
      apiname =
        "api/Agents/GetByDisplayName?name=" +
        "&agentid=0&type=Processor&filterflag=false";

      await this.service.getapiurl(apiname).subscribe({
        next: async data => {
          this.ProcessorList = data as any[];
          this.ProcessorList.map(obj => {
            (obj.name = obj.displayname),
              (obj.displayname = `${obj.displayname}  ${"(" + obj.id + ")"}`);
          });
          if (this.ProcessorList.length > 0) {
            this.ProcessorList = this.service.transformAlphabetically(
              this.ProcessorList,
              "displayname"
            );
          }
          this.ProcessorAllList = this.ProcessorList;
          // this.selectedprocessagentid = '';
          this.isLoadingProcesor = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingProcesor = false;
          this.notfounddata = true;
        },
      });
    }
  }

  selectedtargetagentEvent(
    item //target agent selected Event
  ) {
    this.selectedtargetaccountid = item.LocationGUID;
    this.selectedtargetaccountName = item.locationName;
    this.selectedtargettypeName = item.TypeName;
    this.selectedtargetdeploymentkey = item.deploymentkey;
    this.selectedtargetassociatedaccountids = item.associatedaccountids;
  }

  selectedProcessEvent(item) {
    this.selectedprocessagentid = item.id;
    this.selectedprocessdisplayname = item.displayname;
  }

  isUUID(uuid) {
    let s;

    s = uuid.match(
      "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
    );
    if (s === null) {
      return false;
    }
    return true;
  }

  fromdate: any;
  todate: any;
  totalmessagecount: number = 0;
  displayedColumns: string[] = [
    "messagetype",
    "messageid",
    "transactionid",
    "createddate",
    "options",
  ];
  transactionidvalue: string = "";

  // check360message(case360){
  //   if( ){
  //   return  'col-md-3 message_btns pt-2 text-center'
  //   }else{
  //     return  'col-md-4 message_btns pt-2 text-center'
  //   }
  // }
  onTabChanged(value) {
    this.isSearched = true;

    this.messageFullData = [];
    this.messageFullData = JSON.parse(JSON.stringify(this.messageStatusList));
    this.messageFullData.forEach(ele => {
      ele.selector = new SelectionModel<any>(true, []);
    });
    setTimeout(() => {
      if (this.paginator != undefined) {
        this.paginator.pageIndex = 0;
      }
    });
    if (value.index !== -1) {
      this.selectedIndexTab = value.index;
      if (
        this.case360 !== undefined &&
        this.case360.hubmessagedata !== undefined &&
        this.case360.hubmessagedata.length > 0
      ) {
        this.filterdata360(
          this.messageStatusList[value.index].displayname,
          value.index
        );
      } else if (
        (this.isEngine == "HUB" && this.case360 == undefined) ||
        this.case360.routeid !== undefined
      ) {
        this.selectedmessagestatustype = this.messageStatusList[
          value.index
        ].displayname;
        this.pagenumber = 1;
        this.SearchMessages(false);
      } else {
        this.messageList = this.messageData.MessageDetails;
        this.selectedmessagestatustype = this.mirthStatusList[
          value.index
        ].displayname;
        this.isEngine = this.messageData["engine"];
        switch (this.selectedmessagestatustype) {
          case "All":
            this.getmirthListData(this.messageData.MessageDetails);
            break;
          case "New":
            this.FilterCreatedMessage();
            break;
          case "Success":
            this.FilterSuccessMessage();
            break;
          case "Failure":
            this.FilterFailureMessage();
            break;
          case "Processing":
            this.FilterProcessingMessage();
            break;
          case "Ignore":
            this.FilterIgnoreMessage();
            break;
          case "Others":
            this.FilterOtherMessage();
            break;
          default:
            this.getmirthListData(this.messageData.MessageDetails);
            break;
        }
      }
    }
  }

  getmirthListData(value) {
    this.messageFullData[this.selectedIndexTab].messageList = value;
    this.messageFullData[this.selectedIndexTab].totalmessagecount =
      value.length;
  }
  newmsglistexportdata: any = [];
  SearchMessages(isSearchClicked: boolean) {
    this.selection.clear();
    this.tablediv = false;
    if (
      this.selectedrouteId == "" &&
      this.reactiveFormroute.get("routename").value != "" &&
      this.reactiveFormroute.get("routename").value != null
    ) {
      let returnvalue = this.isUUID(
        this.reactiveFormroute.get("routename").value
      );
      if (returnvalue == true) {
        this.selectedrouteId = this.reactiveFormroute.get("routename").value;
      } else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        return;
      }
    }
    if (
      this.selectedsourceaccountid == "" &&
      this.reactiveFormsource.get("name").value != "" &&
      this.reactiveFormsource.get("name").value != null
    ) {
      let returnvalue = this.isUUID(this.reactiveFormsource.get("name").value);
      if (returnvalue == true) {
        this.selectedsourceaccountid = this.reactiveFormsource.get(
          "name"
        ).value;
      } else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        return;
      }
    }
    if (
      this.selectedtargetaccountid == "" &&
      this.reactiveFormtarget.get("namet").value != "" &&
      this.reactiveFormtarget.get("namet").value != null
    ) {
      let returnvalue = this.isUUID(this.reactiveFormtarget.get("namet").value);
      if (returnvalue == true) {
        this.selectedtargetaccountid = this.reactiveFormtarget.get(
          "namet"
        ).value;
      } else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        return;
      }
    }
    if (
      this.selectedprocessagentid == "" &&
      this.reactiveFormprocess.get("namep").value != "" &&
      this.reactiveFormprocess.get("namep").value != null
    ) {
      let returnvalue = this.isUUID(
        this.reactiveFormprocess.get("namep").value
      );
      if (returnvalue == true) {
        this.selectedprocessagentid = this.reactiveFormprocess.get(
          "namep"
        ).value;
      } else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        return;
      }
    }
    if (this.fromdate == "" && this.todate == "") {
      this.fromdate = undefined;
      this.todate = undefined;
    }

    if (
      (this.fromdate && !this.todate) ||
      (!this.fromdate && this.todate) ||
      (this.fromdate == "" && this.todate == "")
    ) {
      this.toastr.error("Select From date & To date ", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
      return;
    }
    if (this.fromdate > this.todate) {
      this.toastr.error(
        "From date should be  equal  or  less than To date ",
        "",
        { timeOut: 4000, positionClass: "toast-bottom-right" }
      );
      return;
    }
    let entitykeyvalue = "";
    if (this.msgfieldlist.length > 0) {
      entitykeyvalue = this.msgfieldlist.reduce((acc, current) => {
        acc = acc + current.msgvalue + ",";
        return acc;
      }, "");
      entitykeyvalue = entitykeyvalue.slice(0, -1);
    } else if (
      this.msgfieldctrl.value != "" &&
      this.msgfieldctrl.value != null
    ) {
      this.toastr.warning("Invalid Value in Message Field", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
      return;
    }

    if (
      (this.reactiveFormmessageid.value.namemessageid !== null &&
        this.reactiveFormmessageid.value.namemessageid !== "") ||
      (this.transactionidvalue !== null && this.transactionidvalue !== "") ||
      (this.reactiveFormparentmessageid.value.parentmsgid !== null &&
        this.reactiveFormparentmessageid.value.parentmsgid !== "") ||
      (entitykeyvalue !== null && entitykeyvalue !== "")
    ) {
      this.fromdate = null;
      this.todate = null;
    }

    if (isSearchClicked) {
      this.pagenumber = 1;
    }
    var searchdata = {
      routedefinitionid: this.selectedrouteId,
      sourceentityid: this.selectedsourceaccountid,
      destinationentityid: this.selectedtargetaccountid,
      messagetype: this.selectedmessagetype,
      processoragentid: this.selectedprocessagentid,
      messageid: this.reactiveFormmessageid.get("namemessageid").value,
      transactionid: this.transactionidvalue,
      entitykeyvalue: entitykeyvalue,
      filtermessagestage: this.filtermsgtaggel == true ? "ALL" : "HOLD",
      status:
        this.selectedmessagestatustype == ""
          ? "ALL"
          : this.selectedmessagestatustype,
      archivedbcheck: this.archivedbcheck,
      FromDate: this.fromdate == undefined ? "" : this.fromdate + " 00:00:00",
      ToDate: this.todate == undefined ? "" : this.todate + " 23:59:59",
      entityid: this.selectedEntityguid,
      parentmessageid: this.reactiveFormparentmessageid.get("parentmsgid")
        .value,
      pagenumber: this.pagenumber,
      pagesize: this.PageSize,
      SETYPE: this.selectedsourcetypeName,
      SEDEPLOYMENTKEY: this.selectedsourcedeploymentkey,
      DETYPE: this.selectedtargettypeName,
      DEDEPLOYMENTKEY: this.selectedtargetdeploymentkey,
      EETYPE: this.selectedEntityType,
      EEDEPLOYMENTKEY: this.selectedEntityDeploymentkey,
    };
    this.ngxUiLoaderService.start();
    var apiname = "api/HubMessage/GetSearchDetails";
    this.service.searchpostapi(apiname, searchdata).then(
      response => {
        if (response == null) {
          this.ngxUiLoaderService.stop();
          this.arhivecheckbackup = false;
          this.isSearched = false;
          this.messageFullData = [];
          this.toastr.warning(
            "Kindly choose a minimum of one search criteria",
            "Alert",
            {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            }
          );
        } else {
          this.ngxUiLoaderService.stop();
          this.isSearched = true;
          this.isEngine = "HUB";
          this.arhivecheckbackup = searchdata.archivedbcheck;
          this.totalmessagecount = 0;
          if (this.selectedIndexTab !== -1) {
            // if(this.pagenumber > 1){
            //
            //   if(this.messageFullData[this.selectedIndexTab].messageList.length>300){
            //     this.messageFullData[this.selectedIndexTab].messageList.splice(0,100)
            //   }
            //   if( this.messageFullData[this.selectedIndexTab].messagestageList.length>300){
            //     this.messageFullData[this.selectedIndexTab].messagestageList.splice(0,100)
            //   }
            //   this.messageFullData[this.selectedIndexTab].messageList =  this.messageFullData[this.selectedIndexTab].messageList.concat(response.hubmessagedata);
            //   this.messageFullData[this.selectedIndexTab].messagestageList = this.messageFullData[this.selectedIndexTab].messagestageList.concat(response.hubmessagestage);
            //   this.messageFullData[this.selectedIndexTab].totalmessagecount = response.messagecount;
            // }
            //else{
            this.messageFullData = JSON.parse(
              JSON.stringify(this.messageStatusList)
            );
            this.selectedIndexTab = this.messageStatusList.findIndex(
              va => va.displayname === this.selectedmessagestatustype
            );
            this.messageFullData.forEach(ele => {
              ele.selector = new SelectionModel<any>(true, []);
            });
            this.newmsglistexportdata = response.hubmessagedata as any[];
            this.messageFullData[
              this.selectedIndexTab
            ].messageList = response.hubmessagedata as any[];
            this.messageFullData[
              this.selectedIndexTab
            ].messagestageList = response.hubmessagestage as any[];
            this.messageFullData[this.selectedIndexTab].totalmessagecount =
              response.messagecount;
            //}
          }
          if (this.pagenumber > 1) {
            this.totalmessagecount = response.messagecount;
            let newMessageList: any;
            newMessageList = response.hubmessagedata as any[];
            let newMessageStageList: any;
            newMessageStageList = response.hubmessagestage as any[];
            if (newMessageList.length != 0) {
              this.tablediv = true;
              newMessageList.forEach(data => {
                this.messageList.push(data);
                newMessageStageList.forEach(ele => {
                  if (
                    ele.stagedisplay != "" &&
                    ele.messagereferenceid === data.id &&
                    ele.stagedisplay != "A"
                  ) {
                    this.messagestageList.push(ele);
                  }
                });
              });
            }
          } else {
            this.messageList = [];
            this.messagestageList = [];
            this.messageList = response.hubmessagedata as any[];
            this.totalmessagecount = response.messagecount;
            let messagesublist = response.hubmessagestage as any[];
            this.messagestageList = [];
            if (this.messageList.length != 0) {
              this.tablediv = true;
              this.messageList.forEach(data => {
                messagesublist.forEach(ele => {
                  if (
                    ele.stagedisplay != "" &&
                    ele.messagereferenceid === data.id &&
                    ele.stagedisplay != "A"
                  ) {
                    this.messagestageList.push(ele);
                  }
                });
              });
            } else {
              this.tablediv = false;
              this.toastr.warning("No results found!!", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
              });
            }
          }
          if (isSearchClicked) {
            this.paginator.firstPage();
          }
          if (this.pagenumber > 0 && this.paginator != undefined) {
            this.paginator.length = this.totalmessagecount;
          }
        }
      },
      error => {
        this.ngxUiLoaderService.stop();
        this.tablediv = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  InputData: Object;
  OutputData: Object;

  OutputJson: string;
  OutputJsonResponsebody: string;
  fileidValue: string;
  filecontents: any;
  InputDataJson: any[];
  transId: string;
  hash: string;
  senderid: string;
  filecheck: boolean = false;
  displaystage: string = "";
  fileidslist: any = [];
  fileidslisttodownload: any = [];
  stageid: string = "";
  inputmediatypedisplay: boolean = true;
  outputmediatypedisplay: boolean = true;
  messageuniqueid: number = 0;
  messagetypefiledownload: string = "";
  openstagereprocess(content, dataitem, messageidcurrent, sync, routeguid) {
    this.selectedroutedefinitionid = routeguid;
    if (
      (dataitem.stagedisplay == "T" ||
        dataitem.stagedisplay == "D" ||
        dataitem.stagedisplay == "V") &&
      (sync == "false" || sync == "0")
    ) {
      this.reprocessbtn = true;
    } else {
      this.reprocessbtn = false;
    }

    this.stageid = dataitem.id;
    this.displaystage = dataitem.stagename;
    this.displaymessageid = messageidcurrent;

    this.ngxUiLoaderService.start();

    var apiname =
      "api/HubMessage/GetMessageLogDataById?messagelogid=" +
      dataitem.id +
      "&archiveflag=" +
      this.archivedbcheck;
    this.service.getapiurl(apiname).subscribe({
      next: async data => {
        this.messagetypefiledownload = data["messagetype"];
        this.fileidarray = [];
        this.isContainsMultiplefileid = false;
        this.messageuniqueid = data["id"];
        try {
          this.InputData = JSON.parse(data["stageinput"]);
        } catch (e) {
          this.InputData = null;
        }

        try {
          this.OutputData = JSON.parse(data["outputdata"]);
        } catch (e) {
          let xmlDocument;
          try {
            xmlDocument = new DOMParser().parseFromString(
              data["outputdata"],
              "text/xml"
            );
            this.OutputData = beautify(data["outputdata"]);
          } catch {
            this.OutputData = data["outputdata"];
          }
        }

        this.filecontents = null;
        if (this.OutputData !== null) {
          if (dataitem.stagedisplay == "ACK") {
            this.fileOutputcheck = false;
          }

          if (Array.isArray(this.OutputData)) {
            if (this.OutputData.length > 0) {
              if (dataitem.stagedisplay == "ACK") {
                this.fileOutputcheck = false;
              }
              this.isContainsMultiplefileid = true;
              this.fileidarray = this.OutputData;
            }
          } else if (this.OutputData["fileid"] != null) {
            this.fileOutputcheck = true;
            this.fileidOutputValue = this.OutputData["fileid"];
          } else {
            this.fileOutputcheck = false;
          }
        }

        if (this.InputData != null) {
          this.transId = this.InputData["transactionid"];
          this.hash = this.InputData["hash"];
          this.senderid = this.InputData["senderagentid"];

          this.fileidInputValue = this.InputData["fileid"];
          if (this.fileidInputValue != null) {
            this.fileInputcheck = true;
          } else {
            this.fileInputcheck = false;
          }
        } else {
          this.fileInputcheck = false;
        }

        if (this.InputData == null && this.OutputData == null) {
          this.ngxUiLoaderService.stop();
          this.toastr.warning(
            "There is no input and response for " +
            dataitem.stagename +
            " stage ",
            "",
            {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            }
          );
        } else {
          if (dataitem.stagedisplay == "H" && Number(data["releaseflag"]) < 1) {
            this.releasebtn = true;
            this.dataview = true;
          } else if (dataitem.stagedisplay == "H") {
            this.dataview = true;
            this.releasebtn = false;
          } else {
            this.releasebtn = false;
            this.dataview = false;
          }
          if (
            (this.fileInputcheck &&
              this.fileOutputcheck &&
              dataitem.stagedisplay == "H") ||
            this.fileidarray.length > 1
          ) {
            this.fileidslist = [];
            // this.fileidInputValue="MjAyMS8xMC82L3NhbXBsZXBkZjEucGRmXzFmZjEyYjU0LTdlNzAtNGI4MC04ODgzLTVjYWZlMDVhOTdmMA==";
            // this.fileidOutputValue="MjAyMS8xMC82L3NhbXBsZXBkZjMucGRmXzQ0ODZmZmY4LTMyYTYtNGI0Ni1hZTJlLTgwMzNjMmE4N2Q1NQ=="
            if (this.fileidarray.length > 1) {
              this.fileidarray.forEach(element => {
                if (element.fileid) {
                  this.fileidslist.push(element.fileid);
                  this.fileOutputcheck = true;
                }
              });
              if (this.fileInputcheck) {
                this.fileidslist.push(this.fileidInputValue);
              }
            } else {
              this.fileidslist.push(this.fileidInputValue);
              this.fileidslist.push(this.fileidOutputValue);
            }

            if (this.fileidslist.length > 0) {
              let createdby = JSON.parse(
                sessionStorage.getItem("sessionObjectlogin")
              ).userdata["userid"];
              let response = await this.service.getapi(
                "api/Routes/GetFileDataMulti?fileID=" +
                this.fileidslist +
                "&contextId=" +
                this.messageuniqueid +
                "&createdBy=" +
                createdby
              );
              if (response["status"] && response["statusText"]) {
                this.ngxUiLoaderService.stop();
                this.toastr.error("Failure to download file", "", {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
              } else {
                this.ngxUiLoaderService.stop();
                var res = response as any[];
                res.forEach(element => {
                  if (element["fileformat"] == "pdf") {
                    this.ngxUiLoaderService.stop();
                    if (element["fileid"] == this.fileidInputValue) {
                      this.inputfilecontent = this._base64ToArrayBuffer(
                        element["filecontent"]
                      );
                      this.inputmediatypedisplay = false;
                    } else {
                      this.outputfilecontent = this._base64ToArrayBuffer(
                        element["filecontent"]
                      );
                      this.outputmediatypedisplay = false;
                    }
                  } else {
                    var myblob = new Blob([element["filecontent"]], {
                      type: "text/plain",
                    });
                    const outurl = URL.createObjectURL(myblob);
                    fetch(outurl)
                      .then(res => res.text())
                      .then(datatext => {
                        this.ngxUiLoaderService.stop();
                        if (element["fileid"] == this.fileidInputValue) {
                          this.inputfilecontent = datatext;
                          this.inputmediatypedisplay = true;
                        } else {
                          this.outputfilecontent = datatext;
                          this.outputmediatypedisplay = true;
                        }
                      });
                  }
                });
                this.modalService
                  .open(content, {
                    windowClass: "myCustomModalClass",
                    backdrop: "static",
                    keyboard: false,
                  })
                  .result.then(result => { });
              }
            } else {
              this.ngxUiLoaderService.stop();
              this.modalService
                .open(content, {
                  windowClass: "myCustomModalClass",
                  backdrop: "static",
                  keyboard: false,
                })
                .result.then(result => { });
            }
          } else {
            this.ngxUiLoaderService.stop();
            this.modalService
              .open(content, {
                windowClass: "myCustomModalClass",
                backdrop: "static",
                keyboard: false,
              })
              .result.then(result => { });
          }
        }
      },

      error: error => {
        this.ngxUiLoaderService.stop();
      },
    });
  }

  closemessageiotpopup(c) {
    this.editorOptions1.readOnly = true;
    this.messageiotreprocess = false;
    this.backupinputdata = "";
    c("close modal");
  }
  closestagepopup(c) {
    c("close modal");
  }

  

  autoclosetemplate() {
    setTimeout(() => {
      this.modalService.dismissAll();
    }, 3000);
  }

  copyToClip(event: any, message: string) {
    if (event != "") {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }

  GetMessageTypes() {
    var apiname = "api/Lookup/GetMessageType?lookuptype=messagetype";
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        this.messageTypeList = data as any[];
      },
    });
  }

  messageStatusList: any = [];
  GetMessageStatus() {
    var apiname = "api/Lookup/GetMessageType?lookuptype=messagestatus";
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        this.messageStatusList = data as any[];
      },
    });
  }
  reset() {
    this.msgfieldctrl.setValue("");
    this.msgfieldlist = [];
    this.messageFullData = [];
    this.pagenumber = 1;
    this.paginator.pageIndex = 0;
    this.reactiveFormroute.reset();
    this.reactiveFormsource.reset();
    this.reactiveFormroute.reset();
    this.reactiveFormtarget.reset();
    this.reactiveFormprocess.reset();
    this.selectedrouteId = "";
    this.selectedrouteDisplayName = "";
    this.selectedmessagetype = "";
    this.reactiveFormmsgtype.reset();
    this.selectedsourceaccountid = "";
    this.stageid = "";
    this.selectedsourceaccountName = "";
    this.selectedtargetaccountid = "";
    this.selectedtargetaccountName = "";
    this.selectedprocessagentid = "";
    this.selectedprocessdisplayname = "";
    this.reactiveFormmessageid.reset();
    this.selection.clear();
    this.messageList = [];
    this.messagestageList = [];
    this.tablediv = false;
    this.reactiveForentityfield.reset();
    this.reactiveFortransactionid.reset();
    this.filtermsgtaggel = true;
    this.selectedmessagestatustype = "ALL";
    this.archivedbcheck = false;
    this.ProcessorList = this.ProcessorAllList;
    this.reactiveFormprocess.setValue({ namep: "" });
    this.messageTypeList = this.messageTypeAllList;
    this.reactiveFormmsgtype.setValue({ msgname: "" });
    this.reactiveFormmsgstatus.reset();
    this.messageStatusList = this.messageStatusAllList;
    this.reactiveFormmsgstatus.setValue({ msgstatusname: "" });
    this.fromdate = this.datePipe.transform(
      this.getSeventhDay(new Date()),
      "yyyy-MM-dd"
    );
    this.todate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.reactiveFormprocess.patchValue(this.ProcessorList);
    this.reactiveFormmsgtype.patchValue(this.messageTypeList);
    this.reactiveFormmsgstatus.patchValue(this.messageStatusList);
    this.transactionidvalue = "";
    this.isFormView = false;
    this.reactiveFormentity.reset();
    this.isSearched = false;
    this.showSourceTarget = true;
    this.service.messagesData = "";
    this.isEngine = "HUB";
    this.EntityNameList = [];
    this.selectedEntityguid = "";
    this.selectedEntityName = "";
    this.reactiveFormparentmessageid.reset();
    this.pagenumber = 1;
    this.isadvancefilter = false;
    this.messagetypefiledownload = "";
    this.selectedsourcetypeName = "";
    this.selectedsourcedeploymentkey = "";
    this.selectedtargettypeName = "";
    this.selectedtargetdeploymentkey = "";
    this.selectedEntityType = "";
    this.selectedEntityDeploymentkey = "";
    this.selectedtargetassociatedaccountids = "";
  }

  sourcearray: any[];
  destinationarray: any[];
  processorarray: any[];

  getRouteDetails() {
    if (this.selectedrouteId != "") {
      var url = "api/Routes/GetRouteList?id=" + this.selectedrouteId;
      this.ngxUiLoaderService.start();
      this.service.getapiurl(url).subscribe({
        next: data => {
          this.ngxUiLoaderService.stop();
          if (this.selectedrouteId != "") {
            this.selectedrouteDisplayName = data["displayname"];
            this.reactiveFormroute.patchValue({
              routename: this.selectedrouteDisplayName,
            });
          }

          if (data["SENAME"] != "") {
            this.selectedsourceaccountid = data["SEGUID"];
            this.selectedsourceaccountName = data["SENAME"];

            this.reactiveFormsource.patchValue({
              name: this.selectedsourceaccountName,
            });
          }

          if (data["DENAME"] != "") {
            this.selectedtargetaccountid = data["DEGUID"];
            this.selectedtargetaccountName = data["DENAME"];
            this.reactiveFormtarget.patchValue({
              namet: this.selectedtargetaccountName,
            });
          }

          if (data["PANAME"] != "") {
            this.selectedprocessagentid = data["PAID"];
            this.selectedprocessdisplayname = data["PANAME"];
            this.reactiveFormprocess.patchValue({
              namep:
                this.selectedprocessdisplayname +
                "  " +
                "(" +
                this.selectedprocessagentid +
                ")",
            });
          }
          this.selectedmessagetype = data["messagetype"];
        },
        error: error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error(
            "Unable to fetch Pipeline details with ID:" + this.selectedrouteId,
            "",
            {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            }
          );
        },
      });
    }
  }
  fileOutputcheck: boolean = false;

  downloadfile(fileId, iscontainsmultiplefileid) {
    if (iscontainsmultiplefileid && this.fileidarray.length > 0) {
      for (var i = 0; i < this.fileidarray.length; i++) {
        this.downloadcontents(
          this.fileidarray[i].fileid,
          this.fileidarray[i].filename,
          "download",
          ""
        );
      }
    } else {
      this.downloadcontents(fileId, "", "download", "");
    }
  }
  downloadcontent(fileid, filenameatstage) {
    var apiname =
      "api/Routes/GetFileData?fileID=" +
      fileid +
      "&contextId=" +
      this.messageuniqueid +
      "&createdby=" +
      JSON.parse(sessionStorage.getItem("sessionObjectlogin")).userdata[
      "userid"
      ];

    this.service.getapiurlcontent(apiname).subscribe({
      next: data123 => {
        this.ngxUiLoaderService.stop();
        if (data123["statusmessage"] != "") {
          var fileDetails = JSON.parse(data123["statusmessage"]);
          let filename = "";

          if (filenameatstage !== "") {
            filename = filenameatstage;
          } else if (fileDetails.hasOwnProperty("filename")) {
            filename = fileDetails.filename;
          }

          if (filename == "") {
            filename = atob(fileid);
          }

          if (
            typeof fileDetails == "object" &&
            fileDetails.hasOwnProperty("contenttype")
          ) {
            let contentType = fileDetails.contenttype;

            switch (contentType) {
              case "application/xml":
                filename += ".xml";
                break;
              case "application/json":
                filename += ".js";
                break;
              case "application/pdf;base64":
                filename;
                break;
              default:
                filename += ".txt";
                break;
            }
          }
          if (filename != "" && filename.indexOf(".") == -1) {
            filename += ".txt";
          }
          var xmlDocument;
          try {
            xmlDocument = new DOMParser().parseFromString(
              data123.responsebody,
              "text/xml"
            );
          } catch {
            xmlDocument = "";
          }
          if (xmlDocument) {
            data123.responsebody = beautify(data123.responsebody);
          }
          const file = new window.Blob([data123.responsebody], {
            type: "application/octet-stream",
          });
          const downloadAncher = document.createElement("a");
          const fileURL = URL.createObjectURL(file);
          downloadAncher.href = fileURL;
          downloadAncher.download = filename;
          downloadAncher.click();
        }
      },
      error: error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Failure to download file", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      },
    });
  }

  MessageTraceData = <any>[];
  displaymessageid: string = "";
  GetTraceData(mymodalstagelogs, transactionid, traceid, messageid) {
    this.displaymessageid = messageid;
    this.ngxUiLoaderService.start();
    var url =
      "api/HubMessage/GetTraceData?txnid=" +
      transactionid +
      "&traceid=" +
      traceid;
    this.service.getapiurl(url).subscribe({
      next: data => {
        if (data.length > 0) {
          this.MessageTraceData = data as any;
          if (mymodalstagelogs != "") {
            this.modalService
              .open(mymodalstagelogs, {
                windowClass: "myCustomModalClass",
                backdrop: "static",
                keyboard: false,
              })
              .result.then(result => { });
          } else {
            this.downloadFile(
              this.MessageTraceData,
              this.MessageTraceData[0]["traceid"]
            );
          }
        } else {
          this.toastr.warning(
            "There is no logs in this Message ID :" +
            " " +
            this.displaymessageid,
            "",
            {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            }
          );
        }
        this.ngxUiLoaderService.stop();
      },
      error: error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Failure to download logs", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      },
    });
  }

  ConvertToCSV(objArray, headerList) {
    let items = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let separator = ",";
    const columns = Object.keys(items[0]).join(separator);
    const body = items
      .map(item => Object.values(item).join(separator))
      .join("\n");

    return columns + "\n" + body;
  }
  copyToClips(event: any, message: string) {
    event != "" ? copyToClipboard(event) : "";
    this.toastr.success(message + " " + "Copied", "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
    });
  }

  downloadFile(data, filename = "data") {
    let csvData = this.ConvertToCSV(data, [
      "agentid",
      "messageid",
      "method",
      "routedefinitionid",
      "messagetype",
      "senderagentid",
      "sourceentityid",
      "destinationentityid",
      "destinationagentid",
      "response",
      "createddate",
      "fileid",
      "invokedurl",
      "stage",
      "hash",
      "response",
      "processingparam",
      "status",
      "processingtime",
    ]);

    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  download() {
    this.ngxUiLoaderService.start();
    this.downloadFile(
      this.MessageTraceData,
      this.MessageTraceData[0]["traceid"]
    );
    this.ngxUiLoaderService.stop();
  }

  reprocessbtn: boolean;
  releasebtn: boolean = false;
  dataview: boolean = false;

  selection = new SelectionModel<any>(true, []);

  checkeddata: any = [];
  messagereprocessdata: any = [];

  transactionid: any = [];
  senderagentid: any = [];

  // messagesResolve(value) {
  //   if (value.selected.length > 0) {
  //     this.checkeddata = value.selected;
  //     var dataResolve = {
  //       "id": this.checkeddata.map(function (a) { return a.id; }).join(','),
  //       "modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
  //     }
  //     ////console.log(dataResolve);
  //     this.ngxUiLoaderService.start();
  //     this.service.hubmessageResolve(dataResolve.id, dataResolve.modifiedby).subscribe(response => {

  //       if (response['status'] && response['statusText']) {
  //         this.ngxUiLoaderService.stop();
  //         this.toastr.error('Resolve failed', "", {
  //           timeOut: 4000,
  //           positionClass: 'toast-bottom-right'
  //         });
  //       }
  //       else {
  //         this.ngxUiLoaderService.stop();
  //         if (response['status'] == "failure") {
  //           this.toastr.error(response['statusmessage'], "", {
  //             timeOut: 4000,
  //             positionClass: 'toast-bottom-right'
  //           });
  //         }
  //         else {
  //           this.toastr.success('Resolved successfully', "", {
  //             timeOut: 4000,
  //             positionClass: 'toast-bottom-right'
  //           });
  //           if (this.case360 !== undefined && this.case360.hubmessagedata !== undefined && this.case360.hubmessagedata.length > 0) {
  //             this.case360search.emit('')
  //           } else {
  //             this.SearchMessages(false);
  //           }
  //           // this.SearchMessages();
  //           // this.autoclosetemplate();
  //           //this.resetReprocess(indenty)
  //         }
  //       }
  //     }, error => {
  //       this.ngxUiLoaderService.stop();
  //       this.toastr.error('Something Went Wrong!', "", {
  //         timeOut: 4000,
  //         positionClass: 'toast-bottom-right'
  //       });
  //     });
  //   }
  // }

  // reprocessSelected(value, indenty) {

  //   if (value.selected.length > 0) {
  //     this.checkeddata = value.selected;
  //     this.messagereprocessdata = [];

  //     this.checkeddata.forEach(element => {
  //       var dataCons = {
  //         "id": element.id,
  //         "transactionid": element.transactionid,
  //         "sourceagentapikey": element.sourceagentapikey,
  //         "sourceagentid": element.sourceagentid,
  //         "sourceentityid": element.sourceentityid,
  //         "destinationentityid": element.destinationentityid,
  //         "messagetype": element.messagetype,
  //         "mode": element.mode,
  //         "messageid": element.messageid,
  //         "parentmessageid": element.parentmessageid,
  //         "format": element.format,
  //         "fileid": element.messagepayloadreference,
  //         "routedefinitionid": element.routedefinitionid,
  //         "verifyroute": "true",
  //         "modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
  //         "parentfileid": element.parentfileid,
  //         "parentfilename": element.parentfilename
  //       }
  //       this.messagereprocessdata.push(dataCons);
  //     });

  //     this.ngxUiLoaderService.start();
  //     var apiname = "api/HubMessage/BatchMessageReprocess"
  //     this.service.searchpostapi(apiname, this.messagereprocessdata).then(response => {

  //       if (response['status'] && response['statusText']) {
  //         this.ngxUiLoaderService.stop();
  //         this.toastr.error('Reprocess failed', "", {
  //           timeOut: 4000,
  //           positionClass: 'toast-bottom-right'
  //         });
  //       }
  //       else {
  //         this.ngxUiLoaderService.stop();
  //         if (response['status'] == "failure") {
  //           this.toastr.error(response['statusmessage'], "", {
  //             timeOut: 4000,
  //             positionClass: 'toast-bottom-right'
  //           });
  //         }
  //         else {
  //           if (this.case360 !== undefined && this.case360.hubmessagedata !== undefined && this.case360.hubmessagedata.length > 0) {
  //             this.case360search.emit('')
  //           } else {
  //             this.SearchMessages(false);
  //           }

  //           this.toastr.success('Reprocessed successfully', "", {
  //             timeOut: 4000,
  //             positionClass: 'toast-bottom-right'
  //           });
  //           // this.autoclosetemplate();
  //           this.resetReprocess(indenty)
  //         }
  //       }
  //     }, error => {
  //       this.ngxUiLoaderService.stop();
  //       this.toastr.error('Something Went Wrong!', "", {
  //         timeOut: 4000,
  //         positionClass: 'toast-bottom-right'
  //       });
  //     });
  //     // this.selection.clear();
  //     // this.SearchMessages();
  //   }
  // }

  messagesResolve(value, noteResolvedtemplate) {
    this.messageSelector = value;
    this.modalService.open(noteResolvedtemplate, {
      windowClass: "modalConfig",
      backdrop: "static",
      keyboard: false,
    });
  }

  reprocessSelected(value, indenty, noteRprocessedtemplate) {
    this.messageSelector = value;
    this.messageindenty = indenty;
    this.modalService.open(noteRprocessedtemplate, {
      windowClass: "modalConfig",
      backdrop: "static",
      keyboard: false,
    });
  }

  mirthReprocessSelected(value, indenty) {
    if (value) {
      this.checkeddata = value.selected;
      this.messagereprocessdata = [];

      this.checkeddata.forEach(element => {
        var dataCons = {
          logId: element.LogId,
          statusmessage: "",
          // element.MessageStatus
          status: element.status,
        };
        this.messagereprocessdata.push(dataCons);
      });

      this.ngxUiLoaderService.start();
      this.service.reprocessapi(this.messagereprocessdata).subscribe(
        response => {
          if (response["status"] && response["statusText"]) {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Reprocess failed", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          } else {
            this.ngxUiLoaderService.stop();
            // this.messageStatusList.forEach((elem) => {
            //   elem.selector = new SelectionModel<any>(true, []);
            // });
            // this.messageStatusList[this.selectedIndexTab].messageMirthList =  response.mirthMessagedata as any[];
            // this.messageStatusList[this.selectedIndexTab].messagestageMirthList = response.mirthMessagestage as any[];
            // this.messageStatusList[this.selectedIndexTab].totalmessagecountMirth =  response.messagecountMirth;
            // this.messageMirthList = response.mirthMessagedata as any[];
            // this.messagestageMirthList = response.mirthMessagestage as any[];
            // this.totalmessagecountMirth = response.messagecountMirth;

            if (response["status"] == "failure") {
              this.toastr.error(response["statusmessage"], "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
              });
            } else {
              this.SearchMessages(false);
              this.toastr.success("Reprocessed successfully", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
              });
              // this.autoclosetemplate();
              this.resetReprocess(indenty);
            }
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      );
      // this.selection.clear();
      // this.SearchMessages();
    }
  }

  resetReprocess(value) {
    switch (value) {
      case "Created":
        this.createdSelection.clear();
        break;

      case "Success":
        this.successSelection.clear();
        break;

      case "Failure":
        this.failureSelection.clear();
        break;

      case "Auto-Healing":
        this.AutohealingSelection.clear();
        break;

      case "Ignored":
        this.IgnoreSelection.clear();
        break;

      case "Processing":
        this.ProcessingSelection.clear();
        break;

      case "Others":
        this.OtherSelection.clear();
        break;
    }
  }

  isAllSelected(selector, array) {
    const numSelected = selector.selected.length;
    const numRows = array.length;
    return numSelected === numRows;
  }

  masterToggle(selector, array) {
    this.isAllSelected(selector, array)
      ? selector.clear()
      : array.forEach(row => selector.select(row));
    this.selecteditemscount = selector.selected.length;
  }
  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.messageList.length;
  //   return numSelected === numRows;
  // }

  // masterToggle() {
  //   this.isAllSelected() ?
  //     this.selection.clear() :
  //     this.messageList.forEach(row => this.selection.select(row));
  // }

  onSelectAll(event) {
    this.isAddedForReprocess = true;
    const checked = event.checked;
    this.messageList.forEach(item => (item.selected = checked));
  }

  fileidOutputValue: any;
  fileidInputValue: any;
  fileInputcheck: boolean;

  checkboxLabel(selector, array, row?: any) {
    if (!row) {
      let ui = `${this.isAllSelected(selector, array) ? "deselect" : "select"
        } all`;
      this.selecteditemscount = selector.selected.length;
      return ui;
    }
    let ui = `${selector.isSelected(row) ? "deselect" : "select"
      } row ${row.position + 1}`;
    this.selecteditemscount = selector.selected.length;
    return ui;
  }

  //   checkboxLabelMirth(selector, array, row?: any) {
  //     if (!row) {
  //       let ui = `${this.isAllSelected(selector, array) ? 'deselect' : 'select'} all`;
  //       this.selecteditemscount = selector.selected.length;
  //       return ui;
  //     }
  //     let ui = `${selector.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  //     this.selecteditemscount = selector.selected.length;
  //     return ui
  //   }

  // messageMirthList
  // checkboxLabel(row?: any) {
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
  //   }
  //   let ui = `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  //   return ui
  // }

  stagestitle(value) {
    return `${value.stagename}
${value.createddate}`;
  }
  async releasemessage() {
    this.ngxUiLoaderService.start();
    let response = await this.service.getapi(
      "api/HubMessage/ReleaseHoldMessage?messageid=" + this.displaymessageid
    );
    if (response["status"] && response["statusText"]) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Unable to release the Message", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    } else {
      this.autoclosetemplate();
      this.SearchMessages(false);
      this.ngxUiLoaderService.stopAll();
      this.toastr.success("Message released successfully", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }

  outputfilecontent: any;
  inputfilecontent: any;
  mediatypedisplay: boolean = true;
  formatType: string = "xml";
  defaultFormatType: string = 'xml';
  editorOptions = {
    theme: 'vs', language: this.formatType, readOnly: true, wordWrap: 'on', scrollBeyondLastLine: false
  };

  editorInit(data, editor: any, type) {
    let formatType;
    if (this.IsValidXML(data)) {
      formatType = "xml";
    } else if (this.service.IsJsonString(data)) {
      formatType = "json";
    } else {
      formatType = "hl7";
    }
    if (this.defaultFormatType != formatType) {
      this.defaultFormatType = formatType;
      this.editorOptions1 = { ...this.editorOptions1, language: formatType };

      this.editorOptions = { ...this.editorOptions, language: formatType };
    }
  }

  editorOptions1 = { theme: 'vs', language: this.formatType, readOnly: true, wordWrap: 'on', scrollBeyondLastLine: false };

  _base64ToArrayBuffer(base64) {
    var binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  filtermsgtaggel: boolean = true;

  editPipeline(item) {
    sessionStorage.setItem(
      "pipelineid",
      JSON.stringify(item.routedefinitionid)
    );
    this.router.navigate(["/home/createpipeline"]);
  }
  buildtitle(Obj: any) {
    if (typeof Obj == "object" && Obj != null) {
      let name = `(${Obj.Type}):${Obj.LocationName}
      ${Obj.Address1}
      `;
      return name;
    }
  }
  formatXml(xml: any) {
    var formatted = "";
    var reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, "$1\r\n$2$3");
    var pad = 0;
    xml.split("\r\n").map((node: any, index: number) => {
      var indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad != 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      var padding = "";
      for (var i = 0; i < pad; i++) {
        padding += "  ";
      }

      formatted += padding + node + "\r\n";
      pad += indent;
    });
    return formatted;
  }
  editmessageiot(data) {
    this.messageiotreprocess = true;
    this.backupinputdata = data;
    this.editorOptions1 = { ...this.editorOptions1, readOnly: false };
  }
  resetmessageiot() {
    this.messageiotreprocess = false;
    this.message_iot_data["InputData"] = this.backupinputdata;
    this.editorOptions1 = { ...this.editorOptions1, readOnly: true };
  }
  reprocessmessageiot(c) {
    var dataCons = {
      id: this.msgObjforiot.id,
      transactionid: this.msgObjforiot.transactionid,
      sourceagentapikey: this.msgObjforiot.sourceagentapikey,
      sourceagentid: this.msgObjforiot.sourceagentid,
      sourceentityid: this.msgObjforiot.sourceentityid,
      destinationentityid: this.msgObjforiot.destinationentityid,
      messagetype: this.msgObjforiot.messagetype,
      mode: this.msgObjforiot.mode,
      messageid: this.msgObjforiot.messageid,
      parentmessageid: this.msgObjforiot.parentmessageid,
      format: this.msgObjforiot.format,
      fileid: this.msgObjforiot.messagepayloadreference,
      routedefinitionid: this.msgObjforiot.routedefinitionid,
      verifyroute: "true",
      filecontent: this.message_iot_data["InputData"],
      modifiedby: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
        .userdata["userid"],
    };
    this.ngxUiLoaderService.start();
    var apiname = "api/HubMessage/ReprocessFileContent";
    this.service.postapi(apiname, dataCons).then(response => {
      if (response["status"] && response["statusText"]) {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Reprocess failed", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      } else {
        this.ngxUiLoaderService.stop();
        if (response["status"] == "failure") {
          this.toastr.error(response["statusmessage"], "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          this.SearchMessages(false);
          this.closemessageiotpopup(c);
          this.toastr.success("Reprocessed successfully", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
          // this.autoclosetemplate();
        }
      }
    }),
      error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Reprocess failed", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      };
  }

  isLoadingstage: boolean = false;
  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }

  Clearemessagetype(event) {
    this.selectedmessagetype = "";
    this.messageTypeList = this.messageTypeAllList;
  }
  messageTypeAllList: any = [];
  async getMessagetypelist(event: any) {
    this.isLoadingstage = true;
    if (event !== "msgtype") {
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      } else {
        if (this.messageTypeList.length == 0) {
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t =>
          t.displayname.toLowerCase().includes(event.toLowerCase())
        );
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingstage = false;
    } else {
      await this.service
        .getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype")
        .subscribe({
          next: async data => {
            data.forEach(element => {
              this.messageTypeAllList.push({ displayname: element });
            });
            this.messageTypeList = this.messageTypeAllList;

            this.isLoadingstage = false;
            if (data.length == 0) {
              this.notfounddata = true;
            } else {
              this.notfounddata = false;
            }
          },
          error: error => {
            this.isLoadingstage = false;
            this.notfounddata = true;
          },
        });
    }
  }

  isLoadingstatus: boolean = false;
  selectedmessagestatusevent(item) {
    this.selectedmessagestatustype = item.displayname;
  }

  Clearemessagestatus(event) {
    this.selectedmessagestatustype = "All";
  }
  messageStatusAllList: any = [];
  getMessagestatuslist(event: any) {
    this.isLoadingstage = true;
    if (event !== "msgstatus") {
      if (event == undefined) {
        this.messageStatusList = this.messageStatusAllList;
      } else {
        this.messageStatusList = this.messageStatusAllList.filter(t =>
          t.displayname.toLowerCase().includes(event.toLowerCase())
        );
      }
      if (this.messageStatusList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingstage = false;
    } else {
      this.service
        .getapiurl("api/Lookup/GetMessageType?lookuptype=messagestatus")
        .subscribe(data => {
          this.messageStatusAllList = [];
          data.forEach(element => {
            this.messageStatusAllList.push({ displayname: element });
          });
          this.messageStatusAllList.unshift({ displayname: "ALL" });
          this.messageStatusList = JSON.parse(
            JSON.stringify(this.messageStatusAllList)
          );
          this.messageStatusList.forEach(eleem => {
            eleem["messageList"] = [];
            eleem["messagestageList"] = [];
            eleem["totalmessagecount"] = 0;
          });
          this.isLoadingstage = false;
          if (
            this.case360 !== undefined &&
            this.case360.hubmessagedata !== undefined &&
            this.case360.hubmessagedata.length > 0
          ) {
            this.showallcaseinboundmessage();
          }
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        }),
        error => {
          this.isLoadingstage = false;
          this.notfounddata = true;
        };
    }
  }

  exportreport() {
    let csvData;
    let filename = "data.csv";
    const data = [];

    if (this.selection.selected.length > 0) {
      this.checkeddata = this.selection.selected;
      this.checkeddata.forEach(element => {
        data.push({
          Messageid: element.messageid,
          Transactionid: element.transactionid,
          RoutedefinitionId: element.routedefinitionid,
          Routename: element.routename,
          Sourceagentid: element.sourceagentid,
          Destinationagentid: element.destinationagentid,
          Sourceentityid: element.sourceentityid,
          Destinationentityid: element.destinationentityid,
          MessageType: element.messagetype,
          Createddate: element.createddate,
          Mode: element.mode,
        });
      });
    } else {
      this.newmsglistexportdata.forEach(element => {
        data.push({
          Messageid: element.messageid,
          Transactionid: element.transactionid,
          RoutedefinitionId: element.routedefinitionid,
          Routename: element.routename,
          Sourceagentid: element.sourceagentid,
          Destinationagentid: element.destinationagentid,
          Sourceentityid: element.sourceentityid,
          Destinationentityid: element.destinationentityid,
          MessageType: element.messagetype,
          Createddate: element.createddate,
          Mode: element.mode,
        });
      });
    }
    if (data.length > 0) {
      csvData = this.ConvertToCSVmessagelist(data, [
        "Messageid",
        "Transactionid",
        "RoutedefinitionId",
        "Routename",
        "Sourceagentid",
        "Destinationagentid",
        "Sourceentityid",
        "Destinationentityid",
        "MessageType",
        "Createddate",
        "Mode",
      ]);

      let blob = new Blob(["\ufeff" + csvData], {
        type: "text/csv;charset=utf-8;",
      });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);

      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    } else {
      this.toastr.warning("There is no data !", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }
  ConvertToCSVmessagelist(objArray, headerscus) {
    let items = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let separator = ",";
    const columns = Object.keys(items[0]).join(separator);
    const body = items
      .map(item => Object.values(item).join(separator))
      .join("\n");
    if (headerscus == "") {
      return columns + "\n" + body;
    } else {
      return headerscus + "\n" + body;
    }
  }

  CheckValidationStauts(data) {
    if (
      data.status == "11" &&
      (data.messagetype.includes("bar") ||
        data.messagetype.includes("dft") ||
        data.messagetype.includes("order") ||
        data.messagetype.includes("orders") ||
        data.messagetype.includes("result") ||
        data.messagetype.includes("vcdft"))
    ) {
      return true;
    } else {
      return false;
    }
  }

  OnValidation(item) {
    this.ngxUiLoaderService.start();
    this.service
      .GetMessageDetailsByID(item.messageid)
      .pipe(
        mergeMap(data => {
          if (data.length > 0) {
            let obj = {
              CaseId: data[0].CaseGUID.toString(),
              MessageID: item.messageid,
              Deployment: data[0].Deployment,
              Direction: data[0].Direction,
              MessageType: item.messagetype,
            };
            console.log(
              "Input params to data validation api is " + JSON.stringify([obj])
            );
            return this.service.dataValidationapi([obj]);
          } else {
            // Return an observable with default or no data
            return of(null);
          }
        })
      )
      .subscribe(
        ResponseData => {
          this.ngxUiLoaderService.stop();
          if (ResponseData.length > 0) {
            const modalRef = this.modalService.open(DataValidationComponent, {
              windowClass: "Mode_Boot",
              backdrop: "static",
              keyboard: false,
            });
            modalRef.componentInstance.inputDataInModalComponent = ResponseData;
            modalRef.result.then(result => { });
          } else {
            this.ngxUiLoaderService.stop();
            this.toastr.warning("No data found", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          }
        },
        error => {
          this.toastr.warning(
            "Error occured while calling GetMessageDetailsByID api",
            "",
            {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            }
          );
          this.ngxUiLoaderService.stop();
        }
      );
  }

  IsValidXML(data) {
    let xmlDocument: any;
    let isValid = false;
    try {
      xmlDocument = new DOMParser().parseFromString(data, "text/xml");
      isValid = true;
    } catch {
      xmlDocument = "";
      isValid = false;
    }
    return isValid;
  }

  entityCustomFilter = function (EntityNameList: any[], query: string): any[] {
    return EntityNameList;
  };
  async getEntityNames(event: any) {
    var valuesa = event;
    let highlighted;
    if (event.includes(":")) {
      let splitValues = event.split(":");
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":");
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":");
      }
    }
    if (highlighted != "") {
      highlighted = event;
    }
    this.selectedEntityguid = "";
    this.reactiveFormentity.patchValue({
      entityname: event,
    });
    if (valuesa != "" && !valuesa.endsWith(":") && !valuesa.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valuesa.replaceAll(": ", ":");
      //var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueToSendToApiWithOutWhiteSpace;

      this.isLoadingentity = true;
      let obj = {
        lsearch: valueToSendToApiWithOutWhiteSpace,
      };
      //let response = await this.service.getapi(createSearch);
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {
        if (response["status"] && response["statusText"]) {
          this.isLoadingentity = false;
          this.EntityNameList = [];
          this.notfounddata = true;
          this.toastr.error("Unable to fetch Entity Details", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          if (response.length > 0) {
            this.EntityNameList = response;
            this.EntityNameList = this.service.transformAlphabetically(
              this.EntityNameList,
              "LocationName"
            );
            this.EntityNameList.map(
              obj =>
              (obj.LocationName =
                obj.UniqueID !== null && obj.UniqueID !== undefined
                  ? `${obj.LocationName} ${"(" +
                  obj.LocationGUID +
                  ")"} ${"(" + obj.UniqueID + ")"}`
                  : `${obj.LocationName} ${"(" + obj.LocationGUID + ")"}`)
            );
          }
          if (response.length == 0) {
            this.EntityNameList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedsourceaccountid = "";
          this.isLoadingentity = false;
        }
      });
    } else {
      this.EntityNameList = [];
      this.notfounddata = true;
    }
  }
  selectedEntity(item) {
    this.selectedEntityguid = item.LocationGUID;
    this.selectedEntityName = item.LocationName;
    this.selectedEntityType = item.TypeName;
    this.selectedEntityDeploymentkey = item.deploymentkey;
  }
  EntityNameListCleare() {
    this.selectedEntityguid = "";
    this.selectedEntityName = "";
    this.selectedEntityType = "";
    this.selectedEntityDeploymentkey = "";
  }
  searchEntityCleared() {
    this.isLoadingentity = false;
    this.EntityNameList = [];
    this.selectedEntityguid = "";
    this.notfounddata = false;
    this.selectedEntityType = "";
    this.selectedEntityDeploymentkey = "";
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.service.messagesData = "";
  }

  checkvalid(value) {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (
      value !== 0 &&
      value !== "_" &&
      value !== "%" &&
      value !== "+" &&
      value !== "-" &&
      !format.test(value)
    ) {
      this.isvalidSPLchar = false;
    } else {
      this.isvalidSPLchar = true;
    }
  }

  onSearchMirth() {
    // let obj=this.MirthFitlerForm.value;
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (
      this.MirthFitlerForm.value.caseid !== "" &&
      format.test(this.MirthFitlerForm.value.caseid)
    ) {
      this.checkvalid(this.MirthFitlerForm.value.caseid);
    } else {
      this.isvalidSPLchar = false;
    }

    let newobj = {
      handler: this.MirthFitlerForm.value.handler
        ? this.MirthFitlerForm.value.handler
        : "",
      LogId: this.MirthFitlerForm.value.LogId
        ? this.MirthFitlerForm.value.LogId
        : "",
      status: this.MirthFitlerForm.value.status
        ? this.MirthFitlerForm.value.status
        : "",
      caseid:
        this.MirthFitlerForm.value.caseid !== ""
          ? this.MirthFitlerForm.value.caseid
          : 0,
      createddate: this.MirthFitlerForm.value.createddate
        ? this.MirthFitlerForm.value.createddate
        : "",
      processingdate: this.MirthFitlerForm.value.processingdate
        ? this.MirthFitlerForm.value.processingdate
        : "",
      deployment: this.messageData.deployment,
    };
    newobj.createddate = this.datePipe.transform(
      newobj.createddate,
      "yyyy-MM-dd"
    );
    newobj.processingdate = this.datePipe.transform(
      newobj.processingdate,
      "yyyy-MM-dd"
    );

    this.ngxUiLoaderService.start();
    // var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    this.service.getMirthSearch(newobj).subscribe(
      data => {
        if (
          this.invalidlogID == true &&
          format.test(this.MirthFitlerForm.value.LogId)
        ) {
          this.toastr.error("Invaild LogID", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
          this.ngxUiLoaderService.stop();
          // this.messageFullData = [];
          setTimeout(() => {
            this.selectedIndexTab = 0;
            this.messageData.MessageDetails = [];
            this.messageFullData[this.selectedIndexTab].messageList = [];
            this.messageFullData[this.selectedIndexTab].totalmessagecount = 0;
          });
        }

        if (this.isvalidSPLchar) {
          this.toastr.error("Invalid CaseID", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
          this.ngxUiLoaderService.stop();
          // this.messageFullData = [];
          setTimeout(() => {
            this.selectedIndexTab = 0;
            this.messageData.MessageDetails = [];
            this.messageFullData[this.selectedIndexTab].messageList = [];
            this.messageFullData[this.selectedIndexTab].totalmessagecount = 0;
          });
          // this.messageData.MessageDetails=[]
          return;
        }

        if (data !== null) {
          this.ngxUiLoaderService.stop();
          // this.getsaved(data);
          // this.messageFullData =[]
          this.selectedIndexTab = 0;
          this.messageData.MessageDetails = data;

          // this.messageFullData.forEach((ele) => {
          //   ele.selector = new SelectionModel<any>(true, []);
          // });
          this.messageFullData[this.selectedIndexTab].messageList = data;
          this.messageFullData[this.selectedIndexTab].totalmessagecount =
            data.length;
          this.isEngine = this.messageData["engine"];
        }
      },
      error => {
        this.ngxUiLoaderService.stop();
      }
    );
  }

  getMirthData(column: any, event: any) {
    this.isLoadinghandler = false;
    this.isLoadinglogid = false;
    this.isLoadingcaseid = false;
    this.isLoadingstatusMirth = false;
    this.isLoadingCreatedDate = false;
    this.isLoadingProcessingDate = false;

    this.service.getMirthFilters(column, event).subscribe(
      data => {
        if (column == "handler") {
          this.isLoadinghandler = true;
          if (data.length == 0) {
            this.handlerList = [];
            this.isLoadinghandler = false;
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
            this.isLoadinghandler = false;
            this.handlerList = [];
            this.handlerList = data;
          }
        } else if (column == "LogId") {
          this.isLoadinglogid = true;
          if (data.length == 0 && data.length == "") {
            this.logIdList = [];
            this.isLoadinglogid = false;
            this.notfounddata1 = true;
            this.invalidlogID = true;
            // this.invalidcaseid=false;
          } else {
            this.invalidlogID = false;
            // this.invalidcaseid=false;
            this.notfounddata1 = false;
            this.isLoadinglogid = false;
            this.logIdList = [];
            this.logIdList = data;
          }
        } else if (column == "caseid") {
          this.isLoadingcaseid = true;
          if (data.length == 0 && data.length == "") {
            this.caseIdList = [];
            this.isLoadingcaseid = false;
            this.notfounddata2 = true;
            this.invalidcaseid = true;
            // this.invalidlogID=false;
          } else {
            // this.invalidlogID=false;
            this.invalidcaseid = false;
            this.notfounddata2 = false;
            this.isLoadingcaseid = false;
            this.caseIdList = [];
            this.caseIdList = data;
          }
        } else if (column == "status") {
          this.isLoadingstatusMirth = true;
          if (data.length == 0) {
            this.statusMirth = [];
            this.isLoadingstatusMirth = false;
            this.notfounddata3 = true;
          } else {
            this.notfounddata3 = false;
            this.isLoadingstatusMirth = false;
            this.statusMirth = [];
            this.statusMirth = data;
          }
        } else if (column == "createddate") {
          this.isLoadingCreatedDate = true;
          if (data.length == 0) {
            this.createdDate = [];
            this.isLoadingCreatedDate = false;
            this.notfounddata4 = true;
          } else {
            this.notfounddata4 = false;
            this.isLoadingCreatedDate = false;
            this.createdDate = [];
            this.createdDate = data;
          }
        } else if (column == "processingdate") {
          this.isLoadingProcessingDate = true;
          if (data.length == 0) {
            this.processingDate = [];
            this.isLoadingProcessingDate = false;
            this.notfounddata5 = true;
          } else {
            this.notfounddata5 = false;
            this.isLoadingProcessingDate = false;
            this.processingDate = [];
            this.processingDate = data;
          }
        }
      },
      error => {
        this.isLoadinghandler = false;
        this.notfounddata = true;
        this.notfounddata1 = true;
        this.notfounddata2 = true;
        this.notfounddata3 = true;
        this.notfounddata4 = true;
        this.notfounddata5 = true;
        switch (column) {
          case "caseid":
            this.invalidcaseid = true;
            break;
          case "LogId":
            this.invalidlogID = true;
            break;

          default:
            break;
        }
      }
    );
  }
  getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }
  searchHandlerCleared(event) {
    this.isLoadinghandler = false;
    this.handlerList = [];
    this.notfounddata = false;
  }
  searchLogIdCleared(event) {
    this.isLoadinghandler = false;
    this.logIdList = [];
    this.notfounddata1 = false;
    this.invalidlogID = false;
  }
  searchCaseIdCleared(event) {
    this.isLoadinghandler = false;
    this.caseIdList = [];
    this.notfounddata2 = false;
    this.invalidcaseid = false;
  }
  searchStatusMirthCleared(event) {
    this.isLoadinghandler = false;
    this.statusMirth = [];
    this.notfounddata3 = false;
  }
  searchCreatedDateCleared(event) {
    this.isLoadinghandler = false;
    this.createdDate = [];
    this.notfounddata4 = false;
  }
  searchProcessingDateCleared(event) {
    this.isLoadinghandler = false;
    this.processingDate = [];
    this.notfounddata5 = false;
  }
  removemsgfieldvalue(tolist: any): void {
    const index = this.msgfieldlist.indexOf(tolist);

    if (index >= 0) {
      this.msgfieldlist.splice(index, 1);
    }
    this.msgfieldInput.nativeElement.focus();
  }
  addmsgvalue(event: MatChipInputEvent): void {
    if (event.value !== "") {
      const input = event.input;
      const value = event.value;
      let valuelist = [];
      if ((value || "").trim()) {
        if (value.includes("|")) {
          valuelist = value.split("|");
          if (valuelist.length <= 5) {
            valuelist.forEach(X => {
              if (X != "") {
                this.msgfieldlist.push({ msgvalue: X.trim() });
              }
            });
          } else {
            this.toastr.warning("Message Field accepts max 5 values", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
            return;
          }
          this.msgfieldctrl.setValue("");
          this.msgfieldInput.nativeElement.value = "";
        } else {
          this.msgfieldlist.push({ msgvalue: value.trim() });
          this.msgfieldctrl.setValue("");
          this.msgfieldInput.nativeElement.value = "";
        }
      }
      this.msgfieldInput.nativeElement.focus();
    } else {
      this.isfocus = false;
    }
  }
  // onScroll($event) {
  //   let count = 0;
  //   let isvalid = true;
  //   count = (parseInt(this.totalmessagecount.toString()) / 100);
  //   let exactvalue = Math.floor(count);
  //   let result = (count - exactvalue) !== 0;
  //   if (result && ((exactvalue + 1) == this.pagenumber)) {
  //     isvalid = false;
  //   }
  //   else if (this.pagenumber == count) {
  //     isvalid = false;
  //   }
  //   if (isvalid) {
  //     this.ngxUiLoaderService.start();
  //     this.pagenumber += 1;
  //     this.SearchMessages(false);
  //   }

  // }
  event: any;
  getPageDetails(event: { pageIndex: number; pageSize: number }) {
    if (event.pageIndex == 0) {
      this.pagenumber = 1;
    } else {
      this.pagenumber = event.pageIndex + 1;
    }

    this.PageSize = 100;
    this.SearchMessages(false);
  }
  focusmatchipinput() {
    this.msgfieldInput.nativeElement.focus();
  }
  getSeventhDay(date = new Date()) {
    const seventhday = new Date(date.getTime());
    seventhday.setDate(date.getDate() - 6);

    return seventhday;
  }

  async clearnotepopupvalues() {
    this.messageNote = "";
  }
  async closenote(h) {
    await this.clearnotepopupvalues();
    h("close modal");
  }

  async closeResolved(i) {
    await this.clearnotepopupvalues();
    i("close modal");
  }

  async saveNoteReprocess(h) {
    if (this.messageSelector.selected.length > 0) {
      this.checkeddata = this.messageSelector.selected;
      this.messagereprocessdata = [];
      this.checkeddata.forEach(element => {
        var dataCons = {
          id: element.id,
          transactionid: element.transactionid,
          sourceagentapikey: element.sourceagentapikey,
          sourceagentid: element.sourceagentid,
          sourceentityid: element.sourceentityid,
          destinationentityid: element.destinationentityid,
          messagetype: element.messagetype,
          mode: element.mode,
          messageid: element.messageid,
          parentmessageid: element.parentmessageid,
          format: element.format,
          fileid: element.messagepayloadreference,
          routedefinitionid: element.routedefinitionid,
          verifyroute: "true",
          modifiedby: JSON.parse(sessionStorage.getItem("sessionObjectlogin")).userdata["userid"],
          parentfileid: element.parentfileid,
          parentfilename: element.parentfilename,
          note: this.messageNote,
          action: "Reprocessed",
          fromDate: this.fromdate + " 00:00:00",
          toDate: this.todate + " 23:59:59",
          filename: element.filename
        };
        this.messagereprocessdata.push(dataCons);
      });
      this.closenote(h);
      this.ngxUiLoaderService.start();
      var apiname = "api/HubMessage/BatchMessageReprocess";
      this.service.searchpostapi(apiname, this.messagereprocessdata).then(
        response => {
          if (response["status"] && response["statusText"]) {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Reprocess failed", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          } else {
            this.clearnotepopupvalues();
            this.ngxUiLoaderService.stop();
            if (response["status"] == "failure") {
              this.toastr.error(response["statusmessage"], "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
              });
            } else {
              if (
                this.case360 !== undefined &&
                this.case360.hubmessagedata !== undefined &&
                this.case360.hubmessagedata.length > 0
              ) {
                this.case360search.emit("");
              } else {
                this.SearchMessages(false);
              }

              this.toastr.success("Reprocessed successfully", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
              });
              // this.autoclosetemplate();
              this.resetReprocess(this.messageindenty);
            }
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      );
      // this.selection.clear();
      // this.SearchMessages();
    }
  }

  async saveNoteResolved(i) {
    if (this.messageSelector.selected.length > 0) {
      this.checkeddata = this.messageSelector.selected;
      this.messagereprocessdata = [];
      this.checkeddata.forEach(element => {
        var dataCons = {
          id: element.id,
          modifiedby: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
            .userdata["userid"],
          sourceagentid: element.sourceagentid,
          transactionid: element.transactionid,
          routeid: element.routedefinitionid,
          messageid: element.messageid,
          note: this.messageNote,
          action: "Resolved",
          stageid: "-1",
        };
        this.messagereprocessdata.push(dataCons);
      });
      this.closenote(i);
      var apiname = "api/HubMessage/HubMessageStatusUpdate";
      this.ngxUiLoaderService.start();
      this.service.searchpostapi(apiname, this.messagereprocessdata).then(
        response => {
          if (response["status"] && response["statusText"]) {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Resolve failed", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          } else {
            this.ngxUiLoaderService.stop();
            if (response["status"] == "failure") {
              this.toastr.error(response["statusmessage"], "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
              });
            } else {
              this.toastr.success("Resolved successfully", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
              });
              if (
                this.case360 !== undefined &&
                this.case360.hubmessagedata !== undefined &&
                this.case360.hubmessagedata.length > 0
              ) {
                this.case360search.emit("");
              } else {
                this.SearchMessages(false);
              }
              // this.SearchMessages();
              // this.autoclosetemplate();
              //this.resetReprocess(indenty)
            }
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      );
    }
  }

  //view the downloand file
  viewFile(response) {
    let namemodal = {
      name: response.filename,
      fullfile: response.filecontent,
      type: response.fileformat,
    };
    const modalRef = this.modalService.open(FileViewExplorerComponent, {
      windowClass: "myCustomModalViewfile",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.fileData = namemodal;
    modalRef.componentInstance.fileType = response.fileformat;
    modalRef.result.then(() => { });
  }

  closepopupdata(u) {
    u("close modal");
  }

  // CompareMessages(messageData) {
  //   this.showDropdown = true;
  // }
  GetThestagemessage(stagevlaue, eventdata) {
    let data = {
      stagevlaue: stagevlaue,
      messagedata: eventdata._selected,
    };

    const modalRef = this.modalService.open(CompareComponent, {
      windowClass: "myCustomModalClass",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.messagedata = data;
    // modalRef.componentInstance.fileType = response.fileformat;
    modalRef.result.then(() => { });
  }

  HL7Viewer(message: any) {
    if (message == null) {

      this.toastr.error("The given input is empty", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
      return
    }
    if (message.startsWith('MSH|^')) {

      if (this.inputhl7data["InputData"].includes("[tempData1]")) {
        this.inputhl7data["InputData"] = this.inputhl7data["InputData"].replaceAll("[tempData1]", "");
      }
      message = this.inputhl7data["InputData"].replaceAll("[tempData]", "\n");
      if ((!this.inputhl7data["InputData"].includes("[tempData]"))) {
        this.inputhl7data["InputData"] = this.inputhl7data["InputData"].replaceAll("[tempData1]", "\n");
        message = this.inputhl7data["InputData"]
      }
      const modalRef = this.modalService.open(Hl7viewerComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = message;
    }

    else {
      this.toastr.error("The given input not in HL7 format", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }
  async Dataexportreport() {
    let datacons = [];
    let result = [];
    this.newmsglistexportdata.forEach(element => {
      let result = {
        "id": element.routedefinitionid,
        "messageid": element.messageid
      }
      datacons.push(result);
    });
    var apiname = 'api/Routes/ExportPipelineFileData';
    this.ngxUiLoaderService.start();
    let response = await this.service.searchpostapi(apiname, datacons);
    if (response == null) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Given input data got the empty response", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
    else {
      this.exportToCsv(response, this.dataExtractRouteName);
      this.ngxUiLoaderService.stop();
      this.toastr.success("File downloaded successfully", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
    this.ngxUiLoaderService.stop();
  }

  exportToCsv(items: any[], fileName: string): void {

    let finalArrya = []; let selectedItems = [];
    for (let [key, value] of Object.entries(items)) {
      let obj = {
        messageid: key.split(":")[1],
        dataValue: value
      }
      finalArrya.push(obj)
    }

    finalArrya.forEach(element => {
      element.dataValue.forEach(item => {
        let messageobj = {
          "Messageid": element.messageid,
          "Key": item.key,
          "Value": item.value
        }
        selectedItems.push(messageobj);
      })
    });
    if (selectedItems.length > 0) {
      this.CsvDataExtract = this.CSVDataExtract(selectedItems, [
        "Messageid",
        "Key",
        "Value"
      ]);

      let blob = new Blob(["\ufeff" + this.CsvDataExtract], {
        type: "text/csv;charset=utf-8;",
      });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);

      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", "DataExtract.csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
    else {
      this.toastr.warning("There is no data !", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }
  CSVDataExtract(objArray, headerscus) {
    let items = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let separator = ",";
    const columns = Object.keys(items[0]).join(separator);
    const body = items
      .map(item => Object.values(item).join(separator))
      .join("\n");
    if (headerscus == "") {
      return columns + "\n" + body;
    } else {
      return headerscus + "\n" + body;
    }
  }

}

