import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigComponent } from '../config/config.component';
import { Subscription } from 'rxjs';
import { E } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { NewauditComponent } from '../newaudit/newaudit.component';
import { environment } from 'src/environments/environment';
declare const copyToClipboard: any;
@Component({
  selector: 'app-routeversion1',
  templateUrl: './routeversion1.component.html',
  styleUrls: ['./routeversion1.component.scss']
})
export class Routeversion1Component implements OnInit {
  languageMenuTrigger: MatMenuTrigger;
  @Input() inputDataInModalComponent: any;
  notfounddata: boolean;
  SourceAgentNameList = <any>[];
  isLoadingagent: boolean;
  isLoadingTargetagent: boolean;
  TargetAgentNameList = <any>[];
  selectedsourceagentid: any;
  selectedsourceapikey: any;
  selectedtargetagentid: any;
  selectedtargetapikey: any;
  messageTypeList = <any>[];
  messageTypeAllList = <any>[];
  isLoadingmsgtype: boolean;
  selectedtargetaccountid: string;
  isLoadingTarget: boolean;
  TargetAccountList = <any>[];
  selectedsourceaccountid: string;
  isLoadingSource: boolean;
  SourceAccountList = <any>[];
  selectedprocessagentid: string;
  isLoadingProcesor: boolean;
  reactiveFormsource: FormGroup;
  reactiveFormtarget: FormGroup;
  reactiveFormprocess: FormGroup;
  reactiveFormsourceagent: FormGroup;
  reactiveFormTargetagent: FormGroup;
  reactiveFormmode: FormGroup;
  reactiveFormmsgtype: FormGroup;
  reactiveFormroutepolicy: FormGroup;
  reactiveFormAgentFilter: FormGroup;
  reactiveFormpa: FormGroup;
  ProcessorList: any;
  keywordsearch = "displayname";
  RoutePolicyList = [
    { name: 'SA..', value: 'SA..' },
    { name: 'SA.SE.', value: 'SA.SE.' },
    { name: 'SA..DE', value: 'SA..DE' },
    { name: 'SA.SE.DE', value: 'SA.SE.DE' }];
  routepolicyselected: string = '';
  messagetypeselected: string = '';
  sourcedpkeydisplay: string = '';
  sourceentitytype: string = '';
  envSourceProxy: string = '';
  sourcetypedisplay: string = '';
  sourcetypedisplayid: string = '';
  sourcedeployementkey: string = '';
  sourceentityaddress: string = '';
  sourceentityaddress1: string = '';
  sourceentityaddress2: string = '';
  sourcecity: string = '';
  sourcestate: string = '';
  sourcezip: string = '';
  sourcetype: string = '';
  sourceentityprimaryid: string = '';
  selectedsourceaccountName: any;
  targetdpkeydisplay: string = '';
  targettypedisplay: string = '';
  targetentitytype: string = '';
  targettypedisplayid: string = '';
  targetdeployementkey: string = '';
  targetentityaddress: string = '';
  targetentityaddress1: string = '';
  targetentityaddress2: string = '';
  targetcity: string = '';
  targetstate: string = '';
  targetzip: string = '';
  targettype: string = '';
  targetentityprimaryid: string = '';
  selectedtargetaccountName: any;
  messagetype: string;
  selectedprocessname: any;
  keyword = 'name';
  modeItemsList = [
    {
      id: 'Production',
      name: 'Production',
    },
    {
      id: 'Stage',
      name: 'Live',
    },
    {
      id: 'Test',
      name: 'Test',
    }
  ];

  myFlagForSlideToggle: boolean = false;
  modeSelected: any;
  pipelineObj: any = {};
  pagerolepermisions: any = [];
  selectedrouteId: any;
  RouteConfigList = <any>[];
  RouteConfigBackUpList = <any>[];
  filteragentlist = <any>[];
  filteragentlistbackup = <any>[];
  configid: string;
  configjson: string;
  configagentid: string;
  configguids: string;
  configagentapikey: any;
  isLoadingFilteredValue: boolean;
  UniqueID: any;
  selectedrouteDisplayName: any;
  isflasgSize: Subscription;
  formatType: string = "json";
  defaultFormatType:string='json';
  editorOptions= {theme: 'vs', language: this.formatType,readOnly:true, wordWrap: 'on',scrollBeyondLastLine: false
};
  keywordsearcha = "LocationName";
  configstatus: string;
  isPipelineLocked: string;
  isLockVersion1: any;
  lockdescription: any;
  isOpened: boolean;

  constructor(private ngxUiLoaderService: NgxUiLoaderService,
    public service: CommonService,
    private activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal) {
    this.reactiveFormsource = this._fb.group({ sourceentity: '' });
    this.reactiveFormtarget = this._fb.group({ destinationentity: '' });
    this.reactiveFormTargetagent = this._fb.group({ targetagent: '' })
    this.reactiveFormmsgtype = this._fb.group({ msgname: '' });
    this.reactiveFormsourceagent = this._fb.group({ sourceagent: '' });
    this.reactiveFormmode = this._fb.group({ modename: '' });
    this.reactiveFormroutepolicy = this._fb.group({ routepolicy: '' });
    this.reactiveFormAgentFilter = this._fb.group({ filteragent: '' });
    this.reactiveFormpa = this._fb.group({ paname: '' });
  }
isopend:boolean = false


  ngOnInit() {
    this.isflasgSize = this.service.ConfigJsonFlag.pipe().subscribe(data => {
      if(this.service.isopenAlready === false){
        const currentModule = this.router.url.split('/')[2];
        if (data["module"] == currentModule) {
          if (this.service.configDetails.operation == "Add") {
            this.openconfigpopup("big", 'create', '')
          }
          else {
            this.openconfigpopup("big", 'Edit', this.service.configDetails)
          }
        }
      }

      this.service.openedModalConfig =''
    });
    this.UniqueID = this.inputDataInModalComponent.UniqueID;

    this.selectedrouteId = this.inputDataInModalComponent.id;
    this.selectedrouteDisplayName = this.inputDataInModalComponent.displayname;

    this.isLockVersion1 = this.inputDataInModalComponent.IsLocked;
    const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'createpipeline').MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
    actionitems.forEach(element => {
      this.pagerolepermisions[element.MenuName] = true;
    });
    this.GetMessageTypes('msgtype');
    this.getcoreconfigforroute();
    this.getVersion1RouteDetails();

  }
  editorInit(editor: any) {
      if (this.configjson) {
        if (this.configjson.startsWith("<")) {
          this.formatType = "xml";
        } else if (this.configjson.startsWith("{")) {
          this.formatType = "json";
        }
        else{
          this.formatType='hl7'
        }
        if(this.defaultFormatType!=this.formatType){
          this.defaultFormatType=this.formatType;
        this.editorOptions = {
          ...this.editorOptions,
          language: this.formatType
        };
      }
      }
  }
  copyToClip(data, message: any) {
    if (data != '') {

      copyToClipboard(data);

      this.toastr.success(message + ' Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }

    const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'createpipeline').MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
    actionitems.forEach(element => {
      this.pagerolepermisions[element.MenuName] = true;
    });
  }
  closematmenu(){
    this.languageMenuTrigger.closeMenu();
  }
  oldvalue:any
  newvalue:any
  toggle:any
  async saveroute() {
    
    if (this.validateroute()) {
      this.ngxUiLoaderService.start();
      this.toggle= this.pipelineObj.status;
      const insertorupdatepipelineentity = {
        "id": this.selectedrouteId,
        "displayname": this.pipelineObj.name.trimLeft().trimRight(),
        "SEGUID": this.selectedsourceaccountid,
        "SENAME": this.selectedsourceaccountName,
        "SETYPE": this.sourceentitytype,
        "DEGUID": this.selectedtargetaccountid,
        "DENAME": this.selectedtargetaccountName,
        "DETYPE": this.targetentitytype,
        "routepolicy": this.routepolicyselected,
        "messagetype": this.messagetypeselected,
        "PAID": this.selectedprocessagentid,
        "MODIFIERID": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
        "status": this.pipelineObj.status ? 'active' : 'passive',
        "SEDEPLOYMENTKEY": this.sourcedeployementkey,
        "DEDEPLOYMENTKEY": this.targetdeployementkey,
        "UniqueID": Number(this.UniqueID),
        "seaddress1": this.sourceentityaddress1,
        "seaddress2": this.sourceentityaddress2,
        "secity": this.sourcecity,
        "sestate": this.sourcestate,
        "sezip": this.sourcezip,
        "deaddress1": this.targetentityaddress1,
        "deaddress2": this.targetentityaddress2,
        "decity": this.targetcity,
        "destate": this.targetstate,
        "dezip": this.targetzip,
        "mode": this.modeSelected,
        "sourcetype": this.sourcetype,
        "targettype": this.targettype,
        "senderID": this.selectedsourceagentid,
        "recieverID": this.selectedtargetagentid,
        "config": this.pipelineObj.config,
        "sync": this.pipelineObj.sync ? 'true' : 'false',
        "monitoring": this.pipelineObj.monitoring ? 'enabled' : 'disabled',
        "resultack": this.pipelineObj.resultack ? 'true' : 'false',
        "isbatch": this.pipelineObj.isbatch ? 'true' : 'false',

       };
       if (this.inputDataInModalComponent !== null && this.inputDataInModalComponent.Direction !== undefined &&this.inputDataInModalComponent.SEDEPLOYMENTKEY!==undefined&&this.inputDataInModalComponent.SEDEPLOYMENTKEY!==undefined) {
        insertorupdatepipelineentity["INTERFACETYPE"] = this.inputDataInModalComponent.Direction;
        insertorupdatepipelineentity["SEDEPLOYMENTKEY"]= this.inputDataInModalComponent.SEDEPLOYMENTKEY;
        insertorupdatepipelineentity["DEDEPLOYMENTKEY"]= this.inputDataInModalComponent.DEDEPLOYMENTKEY;
    }

       var responseroutedata = await this.service.postapi('api/Routes/SaveVersion1Route', insertorupdatepipelineentity);

       if ((responseroutedata['status'] && responseroutedata['statusText']) || responseroutedata['statusText'] === "Unknown Error") {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Unable to save the Pipeline", "", {
          timeOut: 3000,
          positionClass: 'toast-bottom-right'

        });

       }
       else if (responseroutedata["UniqueID"]) {
        this.ngxUiLoaderService.stop();
        this.autoclosetemplate('save');
        this.Reset();
        this.toastr.success("Pipeline Saved Successfully", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

       let newroutevalue=responseroutedata;
       let auditarray=[]
        let newauditvalues={}
        let oldauditvalues={}
        var responseaudit=''
        if (this.oldvalue != '') {
          this.oldvalue['senderID']=this.oldvalue['senderdisplayname']
          this.oldvalue['recieverID']=this.oldvalue['recieverdisplayname']
          delete this.oldvalue['senderdisplayname']
          delete this.oldvalue['recieverdisplayname']
           newroutevalue['recieverID']=this.targetagtname
           newroutevalue['senderID']=this.sourceagtname
          for(let key in this.oldvalue){
            for(let key2 in newroutevalue){
              if(this.oldvalue[key]!=undefined && newroutevalue[key]!=undefined)
              {
              if(this.oldvalue[key] !==newroutevalue[key])
              {
               newauditvalues[key]=newroutevalue[key];
               oldauditvalues[key]=this.oldvalue[key];
              }
            }
            }
          }
          let oldstring=``
          let newstring=``
          for(let [key,value] of Object.entries(oldauditvalues)){
            for(let [key2,value2] of Object.entries(newauditvalues)){
              if(key==key2){

                oldstring+=`${key}:${value}
                `
                newstring+= `${key2}:${value2}
                `
              }
            }
          }
          if (oldstring != '' && newstring != '') {
            this.oldvalue = "Pipeline Saved Successfully,GUID is " + responseroutedata["ROUTEID"];
             responseaudit = await this.service.auditsave("Add", oldstring, newstring, "Pipeline",responseroutedata["UniqueID"],'Version1 route Modified Successfully')
          }
        //   for(let key in newauditvalues){
        //     if(newauditvalues[key]!=undefined)
        //   {
        //     let param={}
        //      let newvaluestring = '';
        //      let oldvaluestring='';
        //       param={
        //       [key]:newauditvalues[key]
        //     }
        //     newvaluestring = JSON.stringify(param);
        //      param={}
        //     param={
        //       [key]:oldauditvalues[key]
        //     }
        //     oldvaluestring=JSON.stringify(param);
        //     responseaudit = await this.service.auditsave("Add", oldvaluestring, newvaluestring, "Pipeline",responseroutedata["UniqueID"]);
        //   }
         // }
        }


        // if (this.oldvalue != '') {
        //   this.newvalue = JSON.stringify(responseroutedata);
        // }
        // if (this.oldvalue == '' && this.newvalue == '') {
        //   this.oldvalue = "Pipeline Saved Successfully,GUID is " + responseroutedata["ROUTEID"];
        // }
        // //audit here
        // var responseaudit = await this.service.auditsave("Add", this.oldvalue, this.newvalue, "Pipeline", responseroutedata["UniqueID"])

        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
        this.toastr.warning('Unable to save the pipeline', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }
  }
  LockUnlock() {

    this.ngxUiLoaderService.start();
    this.service.requestUnlock(this.UniqueID, JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'], this.pipelineObj['locked'] == 'True' ? 1 : 0,this.pipelineObj['locked'] == 'True' ? 1 : 0, this.lockdescription,this.selectedrouteId,this.sourcedeployementkey).subscribe(response => {
      if (response) {
        this.ngxUiLoaderService.stop();
        if (response.status == 'Success') {
          this.getVersion1RouteDetails()
          this.lockdescription ="";
          this.languageMenuTrigger.closeMenu();
          this.toastr.success(response.statusmessage, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }

      }
      else {
        this.ngxUiLoaderService.stop();
        this.toastr.warning('Please add the Description!!', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }, error => {
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right"
      });
      this.ngxUiLoaderService.stop();
    })
  }
  Reset() {
    this.selectedprocessagentid = '';
    this.selectedsourceaccountid = '';
    this.selectedtargetaccountid = '';
    this.selectedrouteId = "";
    this.UniqueID = '';
    this.selectedrouteDisplayName = "";
    this.selectedsourceaccountName = "";
    this.selectedtargetaccountName = "";
    this.reactiveFormsource.reset();
    this.reactiveFormtarget.reset();
    this.myFlagForSlideToggle = false;
    this.sourcetypedisplay = '';
    this.sourcetypedisplayid = '';
    this.targettypedisplay = '';
    this.targettypedisplayid = '';
    this.sourceentityaddress = '';
    this.sourceentityaddress1 = '';
    this.sourceentityaddress2 = '';
    this.sourcecity = '';
    this.sourcestate = '';
    this.sourcezip = '';
    this.sourcetype = '';
    this.targetentityaddress = '';
    this.targetentityaddress1 = '';
    this.targetentityaddress2 = '';
    this.targetcity = '';
    this.targetstate = '';
    this.targetzip = '';
    this.targettype = '';
    this.modeSelected = '';
    this.reactiveFormmode.reset();
    this.configid = "";
    this.configjson = "";
    this.configagentid = "";
    this.reactiveFormsourceagent.reset();
    this.reactiveFormTargetagent.reset();
    this.SourceAgentNameList = [];
    this.TargetAgentNameList = [];
    this.selectedsourceagentid = "";
    this.selectedtargetagentid = "";
    this.isLoadingagent = false;
    this.isLoadingTargetagent = false;
    this.selectedsourceagentid = "";
    this.selectedsourceapikey = "";
    this.selectedtargetapikey = "";
    this.messagetypeselected = "";
    this.routepolicyselected = "";
    this.reactiveFormroutepolicy.reset();
    this.reactiveFormmsgtype.reset();
    this.messageTypeList = this.messageTypeAllList;
    this.reactiveFormpa.setValue({ "paname": '' });
    this.reactiveFormmsgtype.reset();
    this.reactiveFormmsgtype.setValue({ "msgname": '' });
    this.ProcessorList = [];
    this.SourceAccountList = [];
    this.SourceAgentNameList = [];
    this.TargetAgentNameList = [];
    this.TargetAccountList = [];
    this.routepolicyselected = '';
    this.clearconfigdetails();
    this.reactiveFormAgentFilter.reset();
    this.clearedroutepolicy('');
    this.pipelineObj = '';
    this.messagetypeselected='';
    this.configstatus="";
  };
  closeversion1routepopup() {
    this.Reset();
    this.activeModal.close();
  }
  autoclosetemplate(cnt) {
    // setTimeout(() => {
      this.Reset();
      this.activeModal.close(cnt);
    // }, 3000);
  }
  sourceagtname:any
  getSourceAgentName(event: any) {
    var val = event;
    if (val) {
      this.isLoadingagent = true;

      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=agent&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.SourceAgentNameList = data as any[];
          this.sourceagtname=data[0].displayname
          this.SourceAgentNameList.map(obj => obj.displayname = `${obj.displayname} ${'('} ${obj.id} ${')'}`);
          this.isLoadingagent = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingagent = false;
          this.notfounddata = true;
        }
      });
    }
  }
  targetagtname:any
  getTargetAgentName(event: any) {
    var val = event;
    if (val) {
      this.isLoadingTargetagent = true;

      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=agent&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.TargetAgentNameList = data as any[];
          this.targetagtname=data[0].displayname
          this.TargetAgentNameList.map(obj => obj.displayname = `${obj.displayname} ${'('} ${obj.id} ${')'}`);
          this.isLoadingTargetagent = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingTargetagent = false;
          this.notfounddata = true;
        }
      });
    }
  }
  GetMessageTypes(event) {

    if (event !== 'msgtype') {
      this.isLoadingmsgtype = true;
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      }
      else {
        if (this.messageTypeList.length == 0) {
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingmsgtype = false;
    }
    else {
      this.isLoadingmsgtype = true;
      this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: data => {
          data.forEach(element => {
            this.messageTypeAllList.push({ displayname: element });
          });
          this.messageTypeList = this.messageTypeAllList;

          this.isLoadingmsgtype = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingmsgtype = false;
          this.notfounddata = true;
        }
      });
    }
  }
  selectedsourceagent(item) {
    this.selectedsourceagentid = item.id;
    this.selectedsourceapikey = item.apikey;
  }
  selectedsourceagentcleared() {
    this.isLoadingTargetagent = false;
    this.SourceAgentNameList = [];
    this.selectedsourceagentid = '';
    this.selectedsourceapikey = '';
  }

  selectedtargetagent(item) {
    this.selectedtargetagentid = item.id;
    this.selectedtargetapikey = item.apikey;
  }
  selectedtargetagentcleared() {
    this.isLoadingagent = false;
    this.TargetAgentNameList = [];
    this.selectedtargetagentid = '';
    this.selectedtargetapikey = '';
  }
  async getSourceAccountName(event: any) {
    var valuesa = event;
    this.selectedsourceaccountid = '';

    if (valuesa != '') {

      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;
      this.isLoadingSource = true;
      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingSource = false;
        this.toastr.error("Unable to fetch Source Details", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        if (response.length > 0) {
          this.SourceAccountList = response as any[];
          this.SourceAccountList = this.service.transformAlphabetically(this.SourceAccountList, 'LocationName');
          this.SourceAccountList.map(obj => obj.LocationName = `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
        }
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
        this.selectedsourceaccountid = '';
        this.isLoadingSource = false;
      }
    }
  }

  async getTargetAccountName(event: any) {
    var valueta = event;
    this.selectedtargetaccountid = '';
    if (valueta != '') {

      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueta;
      this.isLoadingTarget = true;
      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingTarget = false;
        this.toastr.error("Unable to fetch Target Details", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        if (response.length > 0) {
          this.TargetAccountList = response as any[];
          this.TargetAccountList = this.service.transformAlphabetically(this.TargetAccountList, 'LocationName');
          this.TargetAccountList.map(obj => obj.LocationName = `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
        }
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }

        this.selectedtargetaccountid = '';
        this.isLoadingTarget = false;
      }
    }

  }
  ProcessorAllList: any = [];
  getProcessorName(event: any) {
    this.selectedprocessagentid = '';
    this.isLoadingProcesor = true;
    let apiname = "api/Agents/GetByDisplayName?name=" + event + '&agentid=0&type=&filterflag=false';

    this.service.getapiurl(apiname).subscribe({
      next: data => {
        this.ProcessorList = data as any[];
        this.ProcessorList.map(obj => { obj.name = obj.displayname, obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}` });
        this.ProcessorAllList = this.ProcessorList;
        this.selectedprocessagentid = '';
        this.isLoadingProcesor = false;
        if (data.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      },
      error: error => {
        this.notfounddata = true;
        this.isLoadingProcesor = false;
        this.toastr.error("Unable to fetch Processor details", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    });
  }
  selectedsourceentity(item) {
    this.selectedsourceaccountid = item.LocationGUID;
    this.selectedsourceaccountName = item.Displayname;
    this.sourcedeployementkey = item.deploymentkey;
    this.sourcedpkeydisplay = item.deploymentkey + '|';

    this.sourceentityaddress = (item.Address1 == null ? '' : item.Address1) + ' ' + (item.Address2 == null ? '' : item.Address2);
    this.sourceentityaddress1 = item.Address1 == null ? '' : item.Address1;
    this.sourceentityaddress2 = item.Address2 == null ? '' : item.Address2;
    this.sourcecity = item.city == null ? '' : item.city;
    this.sourcestate = item.state == null ? '' : item.state;
    this.sourcezip = item.zip == null ? '' : item.zip;
    this.sourceentitytype = item.EntityType;
  }
  selecteddestinationentity(item) {
    this.selectedtargetaccountid = item.LocationGUID;
    this.selectedtargetaccountName = item.Displayname;
    this.targetdeployementkey = item.deploymentkey;

    this.targetdpkeydisplay = item.deploymentkey + '|';
    this.targetentityaddress = (item.Address1 == null ? '' : item.Address1) + ' ' + (item.Address2 == null ? '' : item.Address2);
    this.targetentityaddress1 = item.Address1 == null ? '' : item.Address1;
    this.targetentityaddress2 = item.Address2 == null ? '' : item.Address2;
    this.targetcity = item.city == null ? '' : item.city;
    this.targetstate = item.state == null ? '' : item.state;
    this.targetzip = item.zip == null ? '' : item.zip;
    this.targetentitytype = item.EntityType;
  }

  searchSourceNameCleared(event) {
    this.isLoadingSource = false;
    this.SourceAccountList = [];
    this.selectedsourceaccountName = '';
    this.selectedsourceaccountid = '';
    this.sourcetypedisplay = '';
    this.sourcetypedisplayid = '';
    this.sourcedpkeydisplay = '';
    this.sourceentityaddress = '';
    this.sourceentityaddress1 = '';
    this.sourceentityaddress2 = '';
    this.sourcecity = '';
    this.sourcestate = '';
    this.sourcezip = '';
    this.notfounddata = false;
  }
  searchTargetNameCleared(event) {
    this.isLoadingTarget = false;
    this.TargetAccountList = [];
    this.selectedtargetaccountName = '';
    this.selectedtargetaccountid = '';
    this.targettypedisplay = '';
    this.targettypedisplayid = '';
    this.targetdpkeydisplay = '';
    this.targetentityaddress = '';
    this.targetentityaddress1 = '';
    this.targetentityaddress2 = '';
    this.targetcity = '';
    this.targetstate = '';
    this.targetzip = '';
    this.notfounddata = false;
  }
  Clearemessagetype() {
    this.messagetypeselected = '';
    this.messageTypeList = this.messageTypeAllList;
  }
  selectedmessagetypeevent(event) {
    this.messagetypeselected = event.displayname;
  }
  selectedProcessorAgents(item) {
    this.selectedprocessagentid = item.id;
    this.selectedprocessname = item.displayname;
  }
  searchProcessorAgentCleared(item) {
    this.selectedprocessagentid = "";
    this.selectedprocessname = "";
    this.ProcessorAllList = [];
    this.ProcessorList = [];
  }
  selectedRoutePolicyEvent(item) {
    this.routepolicyselected = item.value;
  }

  selectmodeEvent(item) {
    this.modeSelected = item.name;
  }
  clearedmode(event) {
    this.reactiveFormmode.reset();
    this.reactiveFormmode.setValue({ "modename": '' });
    this.modeItemsList = [
      {
        id: 'Production',
        name: 'Production',
      },
      {
        id: 'Stage',
        name: 'Live',
      },
      {
        id: 'Test',
        name: 'Test',
      }
    ];
    this.reactiveFormmode.patchValue(this.modeItemsList);
  }
  onFocusedroutepolicy(event) {
    this.reactiveFormroutepolicy.setValue({ "routepolicy": '' });
    this.RoutePolicyList = [
      { name: 'SA..', value: 'SA..' },
      { name: 'SA.SE.', value: 'SA.SE.' },
      { name: 'SA..DE', value: 'SA..DE' },
      { name: 'SA.SE.DE', value: 'SA.SE.DE' }];
    this.reactiveFormroutepolicy.patchValue(this.RoutePolicyList);
  }
  clearedroutepolicy(event) {
    this.reactiveFormmode.reset();
    this.reactiveFormroutepolicy.setValue({ "routepolicy": '' });
    this.RoutePolicyList = [
      { name: 'SA..', value: 'SA..' },
      { name: 'SA.SE.', value: 'SA.SE.' },
      { name: 'SA..DE', value: 'SA..DE' },
      { name: 'SA.SE.DE', value: 'SA.SE.DE' }];
    this.reactiveFormroutepolicy.patchValue(this.RoutePolicyList);
    this.routepolicyselected = '';
  }
  onFocusedmode(event) {
    this.reactiveFormmode.setValue({ "modename": '' });
    this.modeItemsList = [
      {
        id: 'Production',
        name: 'Production',
      },
      {
        id: 'Stage',
        name: 'Live',
      },
      {
        id: 'Test',
        name: 'Test',
      }
    ];
    this.reactiveFormmode.patchValue(this.modeItemsList);
  }
  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }
  getcoreconfigforroute() {
    this.ngxUiLoaderService.start();
    var dataPost = {
      guids: this.selectedrouteId,
      filtertype: 'Pipeline',
      //isPipelineLocked:dataPost.isPipelineLocked == "True"?true:false
    };
    this.ngxUiLoaderService.start();
    this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost).then(Response => {
      if (Response) {
        if ((Response['status'] && Response['statusText']) || Response['statusText'] === "Unknown Error") {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        }
        else {
          this.RouteConfigList = Response as any[];
          this.RouteConfigBackUpList = this.RouteConfigList;
          if (this.RouteConfigList.length > 0) {
            this.filteragentlist = [];
            this.filteragentlistbackup = [];
            this.RouteConfigList.forEach(ele => {
              ele['isPipelineLocked']=this.isLockVersion1 == 'True'?true:false
              let obj = {
                name: ele.agentdisplayname,
                displayname: `${ele.agentdisplayname}(${ele.agentid})`,
                id: ele.agentid,
                isPipelineLocked:this.isLockVersion1 == 'True'?true:false
              }
              this.filteragentlist.push(obj);
            });
            this.filteragentlist = [...new Map(this.filteragentlist.map(item =>
              [item["id"], item])).values()];
            this.filteragentlistbackup = this.filteragentlist;
            this.reactiveFormAgentFilter.setValue({ "filteragent": "" })
            this.ngxUiLoaderService.stop();
          }
          this.ngxUiLoaderService.stop();
        }
      }
    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    })
  }
  editconfigjson(data, array) {
    this.configid = '';
    this.configagentid = '';
    this.configguids = '';
    this.configagentapikey = '';
    this.configjson = '';
    array.forEach(ele => {
      ele["active"] = false;
    })
    if (data.json != "{}" && data.json) {
      data["active"] = true;
      this.configjson = JSON.stringify(JSON.parse(data.json), null, 2);
    }
    this.isPipelineLocked = this.isLockVersion1;
    this.configid = data.configid;
    this.configagentid = data.agentid;
    this.configguids = data.guids;
    this.configagentapikey = data.agentapikey;
  }
  configdetails() {
    this.ngxUiLoaderService.start();
    this.getcoreconfigforroute();
    // this.ngxUiLoaderService.stop();
  }
  clearconfigdetails() {
    this.RouteConfigList = [];
    this.filteragentlist = [];
    this.filteragentlistbackup = [];
    this.RouteConfigBackUpList = [];
    this.configid = "";
    this.configjson = "";
    this.configagentid = "";
    this.configguids = '';
  }
  openconfigpopup(isflag: string, type: string, item) {
    this.ngxUiLoaderService.start();
    if (isflag == "small") {
      let data = {};
      if (type == "create") {
        data = {
          pipelineid: this.selectedrouteId,
          pipelinedisplayname: this.selectedrouteDisplayName,
          editjson: "small",
          type: "create",
          configlist: this.RouteConfigBackUpList,
          disablepipeline: true,
          pipelineuniqueid: this.UniqueID
        }
      }
      else {
        data["configid"] = item.configid;
        data["agentid"] = item.agentid;
        data["json"] = item.json;
        if (item["guids"]) {
          data["pipelineid"] = item.guids;
        }
        else {
          data["guids"] = '';
        }
        data["agentdisplayname"] = item.agentdisplayname;
        data["pipelinedisplayname"] = item.pipelinedisplayname;
        data["editjson"] = "small";
        data["type"] = "Edit";
        data["disablepipeline"] = true;
        data["status"] = item.status;
        data["pipelineuniqueid"] = this.UniqueID;
        data["agentapikey"] = item.agentapikey
      }

      const modalRef = this.modalService.open(ConfigComponent,
        { windowClass: "modalConfig", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = data;
      modalRef.result.then((data) => {
        modalRef.close()
        this.isOpened = false;
        if (data == "refresh") {
          this.ngxUiLoaderService.start();
          this.getcoreconfigforroute();
          this.ngxUiLoaderService.stop();
        }
      });
    } else {
      let data = {
        editjson: "big",
        type: type
      }
      if (type != "create" && item == '') {
        data["configid"] = this.configid;
        data["agentid"] = this.configagentid;
        data["json"] = this.configjson;
        data["guids"] = this.configguids ? this.configguids : ''
        data["pipelineuniqueid"] = this.UniqueID;
        data["agentapikey"] = this.configagentapikey;
        data["status"]=this.configstatus;
      }
      else if (type != "create" && item != '') {
        data["configid"] = item.configid;
        data["agentid"] = item.agentid;
        data["json"] = item.json;
        data["guids"] = item.guids;
        data["pipelineuniqueid"] = this.UniqueID;
        data["agentapikey"] = item.agentapikey;
        data["status"]=item.status;
      }
      this.service.isopenAlready = true;
      this.service.openedModalConfig == 'pipelinelist'
      const modalRef = this.modalService.open(ConfigComponent,
        { windowClass: "modalConfigJSON", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = data;
      modalRef.result.then((data) => {
      modalRef.close()
        if (data == "refresh") {
          this.ngxUiLoaderService.start();
          this.configagentid = "";
          this.configid = "";
          this.configjson = "";
          this.configstatus='';
          this.configagentapikey = '';
          this.getcoreconfigforroute();
          this.ngxUiLoaderService.stop();
        }
      });
    }
    this.ngxUiLoaderService.stop();
  }
  getFilteredValues(event) {
    if (event) {
      this.isLoadingFilteredValue = true;
      this.ngxUiLoaderService.start();
      if (this.filteragentlist.length == 0) {
        this.filteragentlist = this.filteragentlistbackup;
      }
      let isfound = false;
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      if (regexExp.test(event)) {
        isfound = true;
        this.filteragentlist = this.filteragentlist.filter(x => x.id == event);
      }
      else {
        isfound = true;
        this.filteragentlist = this.filteragentlist.filter(x => x.name.toLowerCase().includes(event.toLowerCase()));
      }
      if (isfound) {
        this.configagentid = "";
        this.configguids = "";
        this.configid = "";
        this.configjson = "";
        this.configstatus="";
        if (this.filteragentlist.length == 0) {
          this.notfounddata = true;
        }
        this.isLoadingFilteredValue = false;
      }
      this.ngxUiLoaderService.stop();
    }
  }
  selectedFilteredValues(item) {
    if (item) {
      this.RouteConfigList = this.RouteConfigList.filter(x => x.agentid == item.id);
    }
  }
  searchFilteredValuesCleared(item) {
    if (this.RouteConfigList.length != this.RouteConfigBackUpList.length) {
      this.configagentid = "";
      this.configguids = "";
      this.configid = "";
      this.configjson = "";
      this.configstatus="";
      this.RouteConfigList = this.RouteConfigBackUpList;
    }
    this.filteragentlist = this.filteragentlistbackup;
    this.notfounddata = false;
    this.reactiveFormAgentFilter.reset();
    this.reactiveFormAgentFilter.setValue({ "filteragent": "" })
  }
  getVersion1RouteDetails() {
    this.ngxUiLoaderService.start();
    var url = "api/Routes/GetVersion1Routes?routeid=" + this.selectedrouteId;;
    this.service.getapiurl(url).subscribe({
      next: data => {

        // const insertorupdatepipelineentity = {
        //   "ROUTEID": data['id'],
        //   "ROUTENAME": data['displayname'],
        //   "SEGUID": data['SEGUID'],
        //   "SENAME": data['SENAME'],
        //   "SETYPE": data['SETYPE'],
        //   "DEGUID": data['DEGUID'],
        //   "DENAME": data['DENAME'],
        //   "DETYPE": data['DETYPE'],
        //   "SOURCEPROXY": data['sourceagentid'],
        //   "TARGETPROXY": data['destinationagentid'],
        //   "ROUTEPOLICY":  data['routepolicy'],
        //   "MESSAGETYPE": data['messagetype'],
        //   "PAID": data['PAID'],
        //   "MODIFIERID": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
        //   "STATUS": data['status'],
        //   "VERSION":"1",
        //   "SEDEPLOYMENTKEY": data['SEDEPLOYMENTKEY'],
        //   "DEDEPLOYMENTKEY": data['DEDEPLOYMENTKEY'],
        //   "VENDORNAME": data['vendor'],
        // };

         this.oldvalue = data;

        this.ngxUiLoaderService.stop();
        this.pipelineObj["id"] = data["id"];
        this.pipelineObj["name"] = data["displayname"];
        this.pipelineObj['locked']= data["IsLocked"]
        // this.isLockVersion1 = data["IsLocked"]; recieverID
        if (data["senderID"]) {
          this.reactiveFormsourceagent.patchValue({
            sourceagent: data["senderdisplayname"] + `(${data["senderID"]})`
          }
          );
          this.sourceagtname=data["senderdisplayname"];
          this.selectedsourceagentid = data["senderID"];
        }
        if (data["recieverID"]) {
          this.reactiveFormTargetagent.patchValue({
            targetagent: data["recieverdisplayname"] + `(${data["recieverID"]})`
          }
          );
          this.targetagtname=data["recieverdisplayname"];
          this.selectedtargetagentid = data["recieverID"];
        }
        if (data["SEGUID"]) {
          this.reactiveFormsource.patchValue({
            sourceentity: data["SENAME"] + `(${data["SEGUID"]})`
          });
          this.selectedsourceaccountid = data["SEGUID"];
          this.selectedsourceaccountName = data["SENAME"];
        }
        if (data["messagetype"]) {
          this.reactiveFormmsgtype.patchValue({
            msgname: data["messagetype"]
          })
          this.messagetypeselected = data["messagetype"];
        }
        if (data["DEGUID"]) {
          this.reactiveFormtarget.patchValue({
            destinationentity: data["DENAME"] + `(${data["DEGUID"]})`
          })
          this.selectedtargetaccountid = data["DEGUID"];
          this.selectedtargetaccountName = data["DENAME"];
        }
        this.pipelineObj["config"] = data["config"] ? data["config"] : '';
        this.pipelineObj["stage"] = data["stage"] ? data["stage"] : '';

        if (data["status"]) {
          this.pipelineObj["status"] = data["status"].toLowerCase() == "active" ? true : false;
        }
        else {
          this.pipelineObj["status"] = false;
        }
        if (data["routepolicy"]) {
          this.reactiveFormroutepolicy.patchValue({
            routepolicy: data["routepolicy"]
          });
          this.routepolicyselected = data["routepolicy"];
        }
        if (data["sync"]) {
          this.pipelineObj["sync"] = data["sync"] == "true" ? true : false;
        }
        else {
          this.pipelineObj["sync"] = false;
        }
        this.modeSelected = data["mode"] ? data["mode"] : '';
        this.reactiveFormmode.patchValue({
          modename: this.modeSelected
        });
        if (data["PAID"]) {
          this.reactiveFormpa.patchValue({
            paname: data["PANAME"] + `(${data["PAID"]})`
          });
          this.selectedprocessagentid = data["PAID"];
          this.selectedprocessname = data["PANAME"];
        }
        if (data["resultack"]) {
          this.pipelineObj["resultack"] = data["resultack"] == "true" ? true : false;
        }
        else {
          this.pipelineObj["resultack"] = false;
        }
        if (data["isbatch"]) {
          this.pipelineObj["isbatch"] = data["isbatch"] == "true" ? true : false;
        }
        else {
          this.pipelineObj["isbatch"] = false;
        }
        if (data["destinationagentid"]) {
          this.reactiveFormTargetagent.patchValue({
            targetagent: data["recieverdisplayname"] + `(${data["destinationagentid"]})`
          });
          this.selectedtargetagentid = data["destinationagentid"];
        }
        if (data["monitoring"]) {
          this.pipelineObj["monitoring"] = data["monitoring"] == "enabled" ? true : false;
        }
        else {
          this.pipelineObj["monitoring"] = false;
        }
        if (data["createfile"]) {
          this.pipelineObj["createfile"] = data["createfile"] == "enabled" ? true : false;
        }
        else {
          this.pipelineObj["createfile"] = false;
        }
      }
    });
  }
  buildtitle(flag, msg, flagtypeactive, flagtypeinactive) {
    return flag ? msg + '  ' + flagtypeactive : msg + '  ' + flagtypeinactive;
  }
  validateroute() {

    let isValid = true;
    if (!this.selectedsourceagentid) {
      this.toastr.warning("Please select the  Source Agent", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      isValid = false;
    }
    else if (!this.selectedsourceaccountid) {
      this.toastr.warning("Please select the  Source Entity", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      isValid = false;
    }
    else if (!this.selectedtargetagentid) {
      this.toastr.warning("Please select the  Target Agent", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      isValid = false;
    }
    else if (!this.selectedtargetaccountid) {
      this.toastr.warning("Please select the Target Entity", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      isValid = false;
    }
    else if(!this.messagetypeselected){
      this.toastr.warning("Please select the Message Type", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      isValid = false;
    }
    return isValid;
  }
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }


  openAuditPopUp() {

    if (this.selectedrouteId != '') {
      const auditDataToModal = {
        id: this.UniqueID,
        title: this.selectedrouteDisplayName,
        context: "Pipeline",
        type: "ID",
        guid: this.selectedrouteId,
        routename: this.selectedrouteDisplayName,
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };


      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(() => { });
    }

  }



  ngOnDestory() {
    this.isflasgSize.unsubscribe()
  }

}
