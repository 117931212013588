import { E } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  @Input() inputDataInModalComponent: any;
  reactiveFormagent:FormGroup;
  reactiveFormroute:FormGroup;
  reactiveFormAccount: FormGroup;
  keywordsearch:string="displayname";
  keywordaccount = "LocationName";
  isLoadingagent:boolean=false;
  AgentNameList:any[];
  RouteNameList:any[];
  AccountList:any[];
  notfounddata: boolean;
  selectedAgentguid: string;
  selectedAgentName: any;
  guidtypelist=["Pipeline","Entity","Custom","Agent"];
  isLoadingroutes:boolean=false;
  isLoadingAccount:boolean=false;
  selectedrouteId: string;
  selectedrouteDisplayName: string;
  entityprimaryid: string;
  configObj:any;
  title:string='';
  editJson: string ="small";
  editorheight: Number = window.innerHeight - 183;
  formatType: string='json';
  defaultFormatType:string='json';
  currentModule: string;
  disablepipeline: boolean=false;
  statusFlag:boolean=false;
  disableagent: boolean=false;
  customguid:string ='';
  selectedsourceaccountName: any;
  sourcetype: string;
  sourcedeployementkey: any;
  sourceentityaddress1: any;
  sourceentityaddress: string;
  sourcecity: any;
  sourcestate: any;
  sourcezip: any;
  sourceentitytype: any;
  activestatus:boolean=false;
  selectedAgentapikey: string='';
  oldvalueJson:any;
  constructor( private service:CommonService,private _fb: FormBuilder,private ngxUiLoaderService: NgxUiLoaderService, private toastr: ToastrService, private activeModal: NgbActiveModal,private router: Router,  private modalService: NgbModal,) {
    this.reactiveFormagent = _fb.group({ agentname: '' });
    this.reactiveFormroute=_fb.group({routename:''});
    this.reactiveFormAccount=_fb.group({account:''});
    this.inputDataInModalComponent='';
   }

   isflasgSize:Subscription;
   editorOptions = {
    theme: 'vs', language: this.formatType, wordWrap: 'on',scrollBeyondLastLine: false
};
  ngOnInit() {
    this.clear();
    this.ngxUiLoaderService.start();
    this.currentModule = this.router.url.split('/')[2];
    this.isflasgSize = this.service.ConfigJsonFlag.subscribe(data =>{
      if(data=="big"){
        this.editJson="big";
      }
    })
    this.configObj={
      guidType:'',
      json:''
    }
    if(this.inputDataInModalComponent){
      if(this.inputDataInModalComponent.type=="Edit"){
        this.title="Update Config";
      }
      else{
        this.title="Add Config";
        this.configObj.guidType='select';
      }
      if(this.inputDataInModalComponent.editjson=="big"){
        this.editJson=this.inputDataInModalComponent.editjson;
        if(this.inputDataInModalComponent.type=="Edit"){
          let configstatus;
          if(this.service.configDetails && this.service.configDetails["status"]){
            configstatus=this.service.configDetails["status"].toString();
          }
          else if(this.inputDataInModalComponent.status){
            configstatus=this.inputDataInModalComponent.status;
          }
          else{
            configstatus="active";
          }
          let obj={
            configid:this.inputDataInModalComponent.configid,
             agentid:this.inputDataInModalComponent.agentid,
             json:this.inputDataInModalComponent.json,
             guids:this.inputDataInModalComponent.guids,
             status:configstatus,
             modifierid:JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
             operation:'Update',
             uniqueid:this.inputDataInModalComponent.pipelineuniqueid?this.inputDataInModalComponent.pipelineuniqueid:this.inputDataInModalComponent.agentuniqueid,
             ispipelineconfig:this.inputDataInModalComponent.pipelineuniqueid?true:false,
             previousconfigstatus:true,
             agentapikey:this.inputDataInModalComponent.agentapikey,
             filtertype:'Agent'
          }
          ;
          this.activestatus=obj.status=='active'?true:false;
          this.service.configDetails=obj;
          if(this.configObj.json){
            this.configObj.json=JSON.stringify(JSON.parse(this.configObj.json),null,2);
            this.oldvalueJson=JSON.stringify(JSON.parse(this.configObj.json),null,2);
          }
          if(obj.json){
            this.configObj.json=JSON.stringify(JSON.parse(obj.json),null,2);
            this.oldvalueJson=JSON.stringify(JSON.parse(this.configObj.json),null,2);
          }
        }
      }
      else if(this.inputDataInModalComponent.editjson=="small")
      {
        if(this.inputDataInModalComponent.pipelineid){
          this.selectedrouteId=this.inputDataInModalComponent.pipelineid;
          this.selectedrouteDisplayName=this.inputDataInModalComponent.pipelinedisplayname;
          this.reactiveFormroute.patchValue({
          routename: `${this.selectedrouteDisplayName} ${'(' + this.selectedrouteId + ')'}`
        });
        this.configObj.guidType='Pipeline';
        if(this.inputDataInModalComponent.disablepipeline){
          this.disablepipeline=true;
        }
        else{
          this.disablepipeline=false;
        }
        }
        if(this.inputDataInModalComponent.entitydisplayname){
          this.configObj.guidType='Entity';
          this.entityprimaryid=this.inputDataInModalComponent.guids;
          this.reactiveFormAccount.patchValue({
            account:`${this.inputDataInModalComponent.entitydisplayname}${'('+this.inputDataInModalComponent.guids+')'}}`
          });
          this.selectedsourceaccountName=this.inputDataInModalComponent.entitydisplayname;
           this.sourceentitytype=this.inputDataInModalComponent["entitytype"];
           this.sourcetype=this.inputDataInModalComponent["entityaccounttype"];
           this.sourcedeployementkey=this.inputDataInModalComponent["entitydeploymentkey"];
           this.sourceentityaddress=this.inputDataInModalComponent["entityaddress1"];
           this.sourceentityaddress1=this.inputDataInModalComponent["entityaddress2"];
           this.sourcecity=  this.inputDataInModalComponent["entitycity"];
           this.sourcestate =this.inputDataInModalComponent["entitystate"];
           this.sourcezip =this.inputDataInModalComponent["entityzip"];
        }


        if(this.inputDataInModalComponent.agentid){
          this.selectedAgentguid=this.inputDataInModalComponent.agentid,
          this.selectedAgentName=this.inputDataInModalComponent.agentdisplayname
          this.reactiveFormagent.patchValue({
            agentname:`${this.selectedAgentName}${'(' +this.selectedAgentguid+')'}`
          });
          if(this.inputDataInModalComponent.disableagent){
            this.disableagent=true;
          }
          else{
            this.disableagent=false;
          }
        }
        if(this.inputDataInModalComponent.guidtype){
          if(this.inputDataInModalComponent.guids)
          {
            if(this.inputDataInModalComponent.guidtype=="Custom"){
              this.configObj.guidType='Custom';
              this.customguid=this.inputDataInModalComponent.guids;
            }
          }
        }
        if(!this.inputDataInModalComponent.pipelineid){
          if(!this.inputDataInModalComponent.guids && this.inputDataInModalComponent.disableagent && this.inputDataInModalComponent.type=="Edit"){
            this.disablepipeline=true;
          }
        }

        if(this.inputDataInModalComponent.status){
          if(this.inputDataInModalComponent.status.toLowerCase()=='passive'){
              this.statusFlag=false;
              this.activestatus=false;
          }
          else{
              this.statusFlag=true;
              this.activestatus=true;
          }
        }

      }

    }
    else{
      this.configObj.guidType='select';
    }
    this.ngxUiLoaderService.stop();
  }
  selectedagent(item){
    this.selectedAgentguid = item.id;
    this.selectedAgentName = item.name;
    this.selectedAgentapikey=item.apikey;
  }
  getAgentNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingagent = true;

      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.AgentNameList = data as any[];
          this.AgentNameList.map(obj => obj.displayname = `${obj.displayname} ${'('} ${obj.id} ${')'}`);
          this.isLoadingagent = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingagent = false;
          this.notfounddata = true;
        }
      });
    }
  }
  searchAgentCleared(){
    this.isLoadingagent = false;
    this.AgentNameList = [];
    this.selectedAgentguid = '';
    this.notfounddata = false;
  }

  selectedRoute(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
  }
  getRouteNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingroutes = true;
      var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.RouteNameList = data as any[];
          this.RouteNameList.map(obj => obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}`);
          this.isLoadingroutes = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingroutes = false;
          this.notfounddata = true;
        }
      });
    }
  }
  searchRouteNameCleared(event) {
    this.isLoadingroutes = false;
    this.RouteNameList = [];
    this.selectedrouteId = '';
    this.selectedrouteDisplayName = '';
    this.notfounddata = false;
  }
  selectedaccountEvent(item){
    this.entityprimaryid = item.LocationGUID;
    if (item.Type == "A") {
      this.sourcetype = "Account";
    }
    else if (item.Type == "O") {
      this.sourcetype = "Organization";
    }
    else {
      this.sourcetype = item.Type;
    }
    this.selectedsourceaccountName = item.Displayname;
    this.sourcedeployementkey = item.deploymentkey;
    this.sourceentityaddress = (item.Address1 == null ? '' : item.Address1) + ' ' + (item.Address2 == null ? '' : item.Address2);
    this.sourceentityaddress1 = item.Address1 == null ? '' : item.Address1;
    this.sourceentityaddress = item.Address2 == null ? '' : item.Address2;
    this.sourcecity = item.city == null ? '' : item.city;
    this.sourcestate = item.state == null ? '' : item.state;
    this.sourcezip = item.zip == null ? '' : item.zip;
    this.sourceentitytype = item.EntityType;
  }
  getAccountName(value) {
    if (value != '') {
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + value;
      this.isLoadingAccount = true;
      this.service.getapi(createSearch).then(response => {
        if (response['status'] && response['statusText']) {
          this.isLoadingAccount = false;
          this.toastr.error("Unable to fetch Source Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          if (response.length > 0) {
            this.AccountList = response as any[];
            this.AccountList.map(obj => obj.LocationName = `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }
          if (response.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.entityprimaryid = '';
          this.isLoadingAccount = false;
        }
      });
    }
  }
  searchAccountNameCleared(){
    this.entityprimaryid = "";
    this.AccountList = [];
    this.sourcetype='';
    this.sourcestate='';
    this.sourcedeployementkey='';
    this.sourceentityaddress='';
    this.sourceentityaddress1='';
    this.sourceentitytype='';
    this.sourcezip="";
    this.sourcecity="";
    this.selectedsourceaccountName="";
  }
  resetguidtype(item){
    this.reactiveFormroute.reset();
    this.reactiveFormAccount.reset();
    this.AccountList=[];
    this.RouteNameList=[];
    this.selectedrouteId='';
    this.selectedrouteDisplayName='';
    this.entityprimaryid='';
    this.sourcetype='';
    this.sourcestate='';
    this.sourcedeployementkey='';
    this.sourceentityaddress='';
    this.sourceentityaddress1='';
    this.sourceentitytype='';
    this.sourcezip="";
    this.sourcecity="";
    this.selectedsourceaccountName="";
  }
  clear(){
    this.reactiveFormroute.reset();
    this.reactiveFormAccount.reset();
    this.reactiveFormagent.reset();
    this.AccountList=[];
    this.RouteNameList=[];
    this.selectedrouteId='';
    this.selectedrouteDisplayName='';
    this.entityprimaryid='';
    this.AgentNameList=[];
    this.selectedAgentName="";
    this.selectedAgentguid="";
    this.statusFlag=false;
    this.disablepipeline=false;
    this.disableagent=false;
    this.sourcetype='';
    this.sourcestate='';
    this.sourcedeployementkey='';
    this.sourceentityaddress='';
    this.sourceentityaddress1='';
    this.sourceentitytype='';
    this.sourcezip="";
    this.sourcecity="";
    this.selectedsourceaccountName="";
    this.customguid="";
    this.activestatus=false;    
  }

  closetpopup(type){
    this.selectedrouteId='';
    this.entityprimaryid='';
    this.customguid='';
    this.clear();
    this.activeModal.close();
    this.service.isopenAlready = false;
    if(this.currentModule !== 'pipelinelist'){
      this.activeModal.close();
    }
    this.service.changeConfigmodal(true);    
  }

  openjsoneditor(){    
    if(this.validate() || this.inputDataInModalComponent.editjson=="big"){
      if(this.inputDataInModalComponent.editjson=="big" && this.configObj.json==""){
        this.toastr.warning("Please enter the required fields", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else{
        if(this.inputDataInModalComponent.editjson=="small" && (this.inputDataInModalComponent.type=="create"||this.inputDataInModalComponent.type=="Edit")){
          let configId='';
          if(this.inputDataInModalComponent.type=="create"){
            configId=this.selectedAgentguid;
            if(this.selectedrouteId){
              configId+=+'_'+this.selectedrouteId;
            }
            else if(this.entityprimaryid){
              configId+='_'+this.entityprimaryid;
            }
            else if(this.customguid){
              configId+='_'+this.customguid;
            }
            configId+='_config';
          }
          else{
            configId=this.inputDataInModalComponent.configid;
          }

          let obj={
            configid:configId,
            agentid:this.selectedAgentguid,
            json:this.inputDataInModalComponent.json?this.inputDataInModalComponent.json:'',
            guids:this.selectedrouteId,
            status:this.statusFlag?'active':'passive',
            modifierid:JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
            operation:this.inputDataInModalComponent.type=="create"?'Add':'Update',
            type:'Pipeline',
            uniqueid:this.inputDataInModalComponent.pipelineuniqueid?this.inputDataInModalComponent.pipelineuniqueid:this.inputDataInModalComponent.agentuniqueid,
            ispipelineconfig:this.inputDataInModalComponent.pipelineuniqueid?true:false,
            previousconfigstatus:this.activestatus,
            agentapikey:this.selectedAgentapikey?this.selectedAgentapikey:this.inputDataInModalComponent?this.inputDataInModalComponent.agentapikey?this.inputDataInModalComponent.agentapikey:'':''
          }
          if(this.configObj.guidType=="Entity"){
            obj["guids"]=this.entityprimaryid;
            obj["entityname"]=this.selectedsourceaccountName;
            obj["entitytype"]=this.sourceentitytype;
            obj["entityaccounttype"]=this.sourcetype;
            obj["entitydeploymentkey"]=this.sourcedeployementkey;
            obj["entityaddress1"]=this.sourceentityaddress;
            obj["entityaddress2"]=this.sourceentityaddress1;
            obj["entitycity"]=this.sourcecity;
            obj["entitystate"]=this.sourcestate;
            obj["entityzip"]=this.sourcezip;
            obj["type"]="Entity";
          }
          else if(this.configObj.guidType=="Custom"){
            obj["guids"]=this.customguid;
            obj["type"]="Custom";
          }
          let modalObj={
            type:"big",
            module:this.currentModule
          }
          if(this.inputDataInModalComponent.type=="create"){
            if(this.inputDataInModalComponent.configlist){
              let configlist=this.inputDataInModalComponent.configlist;
              let exists=configlist.filter(x=>x.configid==configId);
              if(exists.length>0){
                this.toastr.warning("Configuration already exists!", "", {
                  timeOut: 4000,
                  positionClass: 'toast-bottom-right'
                });
              }
              else{
                this.service.configDetails=obj;
                this.closetpopup('"refresh"')
                this.service.changeModal(modalObj);
              }
            }

          }
          else{
            this.service.configDetails=obj;
            this.saveconfig(obj,'');

          }

        }
        else if(this.inputDataInModalComponent.editjson=="big" && (this.inputDataInModalComponent.type=="Edit" || this.inputDataInModalComponent.type=="create")){
          let obj=this.service.configDetails;
          if(this.service.IsJsonString(this.configObj.json)){
            obj.json=this.configObj.json;
            this.saveconfig(obj,'refresh');
          }
          else{
            this.toastr.warning("Invalid JSON", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }

        }
      }
    }
    else{
      this.toastr.warning("Please enter the required fields", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  saveconfig(obj,type){
     this.ngxUiLoaderService.start();
      this.service.postapi("api/CoreConfig/SaveConfig", obj).then(data => {
        if (data) {
          if((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error"){
            this.ngxUiLoaderService.stop();
            let message="";

            if(obj.operation=="Add"){
              message="Failure to Create the Config";
            }
            else{
              message="Failure to Update the Config";
            }
            this.toastr.error(message, "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
          else{
          this.ngxUiLoaderService.stop();
          let module='';
          let displaymessage='';
          if(obj.hasOwnProperty("uniqueid")&& (obj.hasOwnProperty("ispipelineconfig"))){
            if(obj.ispipelineconfig){
              module='Pipeline'
            }
            else{
              module='Agent'
            }
          }
          if(this.inputDataInModalComponent.editjson=="small"){
            if(obj.operation!="Add"){
              if(obj.status=="active" && !obj.previousconfigstatus){
                displaymessage = module=='Agent'?'Agent Config activated successfully with configId '+obj.configid:'Pipeline Config activated Successfully with configId '+obj.configid;
                this.service.auditsave("Update", "", displaymessage, module, obj.uniqueid,'Config Updated Successfully').then(response => {
                })
              }
              else if(obj.status=="passive" && obj.previousconfigstatus){
                displaymessage = (module=='Agent')?'Agent Config deactivated successfully with configId '+obj.configid:'Pipeline Config deactivated Successfully with configId '+obj.configid;
                this.service.auditsave("Update", "",displaymessage , module, obj.uniqueid,'Config Updated Successfully').then(response => {
                })
              }
          }
          else{
            displaymessage=obj.operation=="Add"?'Config Created Successfully with configId '+obj.configid:'Config Updated Successfully with configId '+obj.configid;
            this.service.auditsave((obj.operation=="Add"?"Add":"Update"), "", displaymessage, module, obj.uniqueid,displaymessage);
            delete obj.uniqueid;
            delete obj.ispipelineconfig;
          }
          }
          else if(this.inputDataInModalComponent.editjson=="big"){
            displaymessage=obj.operation=="Add"?'Config Created Successfully with configId '+obj.configid:'Config Updated Successfully with configId '+obj.configid;
            this.service.auditsave((obj.operation=="Add"?"Add":"Update"), "", displaymessage, module, obj.uniqueid,displaymessage);
            delete obj.uniqueid;
            delete obj.ispipelineconfig;
          }
          if (displaymessage === '') {
            displaymessage = 'Config Updated Successfully with configId ' + obj.configid;
        }
            this.toastr.success(displaymessage, "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            this.closetpopup('"refresh"');
           
            if(this.inputDataInModalComponent.type=="Edit" && this.inputDataInModalComponent.editjson=="small"){
              let modalObj={
                type:"big",
                module:this.currentModule
              }
              this.closetpopup('refresh');
              // if(this.editJson !== 'big' ){
              //   this.service.changeModal(modalObj);
              // }
            }
            else if(type){
              this.closetpopup('refresh');
            }
        }
      }
      },error=>{
        this.ngxUiLoaderService.stop();
        this.toastr.error("Something Went Wrong", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      });

  }
  validate():boolean {
    let isvalid=true;
    if(this.selectedAgentguid=="" && !this.selectedAgentguid){
      isvalid=false;
    }
    else if(this.configObj.guidType=="select"){
      isvalid=false;
    }
    else if(this.configObj.guidType=='Pipeline' && !this.selectedrouteId){
      isvalid=false;
    }
    else if(this.configObj.guidType=='Entity' && !this.entityprimaryid){
      isvalid=false;
    }
    else if(this.configObj.guidType=='Custom'  && this.customguid.replace(/\s/g,"")==""){
      isvalid=false;
    }
    return isvalid;
  }
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }
  editorInit(editor: any) {
    if (this.configObj.json) {
      if (this.configObj.json.startsWith("<")) {
          this.formatType = "xml";
      } else if (this.configObj.json.startsWith("{")) {
          this.formatType = "json";
      }else{
        this.formatType = "hl7";
      }
  }
if(this.defaultFormatType!=this.formatType){
  this.defaultFormatType=this.formatType;
        this.editorOptions = {
            ...this.editorOptions,
            language: this.formatType
        };
      }
}
registerMonacoCustomTheme() {
  monaco.editor.defineTheme("myCustomTheme", {
      base: "vs", // can also be vs or hc-black
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
          {
              token: "comment",
              foreground: "ffa500",
              fontStyle: "italic underline"
          }, {
              token: "comment.js",
              foreground: "008800",
              fontStyle: "bold"
          }, {
              token: "comment.css",
              foreground: "0000ff"
          }, // will inherit fontStyle from `comment` above
      ],
      colors: {}
  });
}
formatData(xml: any) {
  var formatted = "";
  var reg = /(>)(<)(\/*)/g;
  xml = xml.replace(reg, "$1\r\n$2$3");
  var pad = 0;
  xml.split("\r\n").map((node: any, index: number) => {
      var indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
          indent = 0;
      } else if (node.match(/^<\/\w/)) {
          if (pad != 0) {
              pad -= 1;
          }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
          indent = 1;
      } else {
          indent = 0;
      }

      var padding = "";
      for (var i = 0; i < pad; i++) {
          padding += "  ";
      }

      formatted += padding + node + "\r\n";
      pad += indent;
  });
 return formatted;
}

deepCompare = (arg1, arg2) => {
  if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)){
    if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]' ){
      if (Object.keys(arg1).length !== Object.keys(arg2).length ){
        return false;
      }
      return (Object.keys(arg1).every((key)=>{
        return this.deepCompare(arg1[key],arg2[key]);
      }));
    }
    return (arg1===arg2);
  }
  return false;
}
ngOnDestory() {
  this.isflasgSize.unsubscribe();
}
}
