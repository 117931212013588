import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
declare const copyToClipboard: any;

@Component({
  selector: 'app-messageattributes',
  templateUrl: './messageattributes.component.html',
  styleUrls: ['./messageattributes.component.scss']
})
export class MessageattributesComponent implements OnInit {
  @Input() inputDataInModalComponent: any;
  reactiveFormmessageattribute: FormGroup;
  messageattubutslist;
  isflag: string;
  updatemapping: any;
  @ViewChild("fileInput", { static: false }) myFileInput;
  messageattibuitemodel: any = {};
  interfacerouteid: string;
  classObjrequiredmessage: string;
  requiredmessage: string;
  hideSuccessMessage = false;
  headerRoute: string = '';
  uniqueid: any;
  copyinterfacemapping: boolean = false;
  templatename: any;
  previousmessageattibute: {};
  enabledownloadmessageattibutes: boolean = false;
  interfaceName: any;
  addmapping: any;
  LockMesAttr: any;
  searchtextData: string = '';

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    public service: CommonService,
    private activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,

  ) {
    this.reactiveFormmessageattribute = _fb.group({
      Searchkey: [''],
      SearchValue: [''],
      Contextid: [''],
      MessageFormat: [''],
      Id: [''],
    }),
      this.messageattubutslist = <any>[];

  }

  tripfilter = {
    Searchkey: '',
    SearchValue: '',
    MessageFormat: ''
  }

  pagerolepermisions: any = [];
  async ngOnInit() {

    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'createpipeline').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });
      if (this.pagerolepermisions != undefined) {

        this.headerRoute = this.inputDataInModalComponent.routename;
        this.uniqueid = this.inputDataInModalComponent.uniqueid;
        this.templatename = this.inputDataInModalComponent.templatename;
        this.interfacerouteid = this.inputDataInModalComponent.routeid;
        this.LockMesAttr = this.inputDataInModalComponent.lockMessAttribute;
        await this.getmessageattibutes();

      }
    }
  }

  getFilter() {
    this.tripfilter.Searchkey = this.searchtextData;
    this.tripfilter.SearchValue = this.searchtextData;
    this.tripfilter.MessageFormat = this.searchtextData;
  }

  getmessageattibutes() {
    this.clearmessageattibutes();
    this.ngxUiLoaderService.start();


    this.service.getapiurl('api/MessageAttributes/GetConfig?routeUniqueId=' + this.uniqueid).subscribe({
      next: data => {
        this.messageattubutslist = data as Messageattributesdata[];
        if (this.messageattubutslist.length > 0) {
          let exists = this.messageattubutslist.filter(x => x.ContextId == this.uniqueid);
          if (exists.length > 0) {
            this.enabledownloadmessageattibutes = true;
            this.reactiveFormmessageattribute.get("MessageFormat").patchValue(exists[0].MessageFormat);
            this.messageattubutslist=this.messageattubutslist.filter(x => x.ContextId == this.uniqueid);
          }
          else {
            this.enabledownloadmessageattibutes = false;
          }
        }
        this.ngxUiLoaderService.stop();
      },
      error: error => {
        this.ngxUiLoaderService.stop();
      }
    });

  }

  clearmessageattibutes() {
    this.reactiveFormmessageattribute.reset();
    this.messageattibuteid = 0;
    this.selectedRow = -1;
    this.messageattibuitemodel.Searchkey = '';
    this.messageattibuitemodel.SearchValue = '';
    this.messageattibuitemodel.ContextId = '';
    this.messageattibuitemodel.Context = '';
    this.messageattibuitemodel.MessageFormat = '';
    this.messageattibuitemodel.Id = '';
    this.messageattibuitemodel.searchtextData='';
    this.previousmessageattibute = '';
  }

  selectedRow: any;
  messageattibuteid: any = "0";
  editmessageattibute(item: any, index: any) {
    this.selectedRow = index;
    this.messageattibuteid = item.Id || item.id;
    this.reactiveFormmessageattribute.patchValue({
      Searchkey: item.Searchkey,
      SearchValue: item.SearchValue,
      Contextid: item.Contextid,
      MessageFormat: item.MessageFormat,
      Id: item.Id,
    });
    this.previousmessageattibute = {
      Searchkey: item.Searchkey,
      SearchValue: item.SearchValue,
      Contextid: item.Contextid,
      MessageFormat: item.MessageFormat,
      Id: item.Id,
    }
  }

  async savemessageattibute() {
    const objma = {
      "Id": this.messageattibuteid,
      "MessageFormat": this.messageattibuitemodel.MessageFormat,
      "Searchkey": this.messageattibuitemodel.Searchkey,
      "SearchValue": this.messageattibuitemodel.SearchValue,
      "ContextId": this.uniqueid,
      "Context": "routeuniqueid"
    }
    if (this.messageattibuitemodel.Searchkey != '' && this.messageattibuitemodel.SearchValue != '' &&
      this.messageattibuitemodel.Searchkey != undefined && this.messageattibuitemodel.SearchValue != undefined
      && this.messageattibuitemodel.MessageFormat != '' && this.messageattibuitemodel.MessageFormat != undefined) {
      this.ngxUiLoaderService.start();
      let response = await this.service.postapi('api/MessageAttributes/SaveOrUpdate', objma);
      if (response['status'] && response['statusText']) {

        this.ngxUiLoaderService.stop();
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        this.ngxUiLoaderService.stop();
        var res = response as any[];
        if (res == null || res['Id'] == '-1') {
          this.toastr.info("Record Already Exists", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          let auditMessage, type, previousmessage = "";

          if (this.messageattibuteid == 0) {
            auditMessage = "Added Message Attibutes  : Searchkey :- " + this.messageattibuitemodel.Searchkey + " , SearchValue:- " + this.messageattibuitemodel.SearchValue;
            type = "Save";
          }
          else {
            previousmessage = "Previous Message Attibutes: Searchkey :- " + this.previousmessageattibute["Searchkey"] + " , SearchValue :- " + this.previousmessageattibute["SearchValue"];
            auditMessage = "Updated Message Attibutes:  Searchkey :-  " + this.messageattibuitemodel.Searchkey + " , SearchValue :- " + this.messageattibuitemodel.SearchValue;
            type = "Update";
          }
          this.service.auditsave(type, previousmessage, auditMessage, "MessageAttributes", this.uniqueid,type+'Message Attibutes').then(response => {
            if (response) {

            }
          });

          this.messageattibuteid = res["Id"];
          this.toastr.success("Message Attibutes Saved Successfully", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          this.getmessageattibutes();
          this.clearmessageattibutes();
        }
      }
    }
    else {
      this.toastr.error("Please fill in the mandatory fields", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }

  }

  async deletemessageattibute(item: any) {
    this.ngxUiLoaderService.start();
    let response = await this.service.deletemapping('api/MessageAttributes/DeleteConfig?Id=' + item.Id);
    if (response['status'] && response['statusText']) {

      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      let auditMessage = "Deleted Message Attribute :  Search key :- " + item.Searchkey + " , Search Value :- " + item.SearchValue
        + " , Message Format :- " + item.MessageFormat
      this.service.auditsave("Delete", "", auditMessage, "MessageAttributes", this.uniqueid,'Deleted Message Attribute' ).then(response => {
        if (response) {

        }
      });
      this.toastr.success("Message Attribute Deleted successfully", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    this.getmessageattibutes();
    this.ngxUiLoaderService.stop();
  }

  FadeOutSuccessMsg() {
    this.hideSuccessMessage = true;
    setTimeout(() => {
      this.hideSuccessMessage = false;
    }, 20000);
  }

  closetpopup() {
    this.activeModal.close();
  }

  fileToUpload: any;
  bulkUploadBtn: any;


  theFile: any = null;
  messages: string[] = [];
  Getfileinfo(event) {
    this.ngxUiLoaderService.start();
    this.theFile = null;
    if (event.target.files && event.target.files.length > 0) {
      this.readAndUploadFile(event.target.files[0]);
      this.myFileInput.nativeElement.value = "";
    }
    else {
      this.myFileInput.nativeElement.value = "";
    }
    this.ngxUiLoaderService.stop();
  }

  private readAndUploadFile(theFile: any) {
    let file = new FileToUpload();
    file.routeid = this.interfacerouteid;
    let reader = new FileReader();
    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result.toString();
      // POST to server
      this.service.uploadFile('api/XMLMappings/UploadBulkMappings', file).subscribe(resp => {
        if (resp.status == 'Success') {
          this.service.auditsave("Add", "", "Message Attributes Uploaded", "MessageAttributes", this.uniqueid,"Message Attributes Uploaded Successfully").then(response => {
            if (response) {
              this.ngxUiLoaderService.stop();
              this.toastr.success(resp.statusmessage, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              this.getmessageattibutes();
            }
          });

        }
        else {
          this.ngxUiLoaderService.stop();
          this.toastr.error(resp.statusmessage, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        }
      });
    }
    // Read the file
    reader.readAsDataURL(theFile);
  }
  downloadinterfacemapping() {
    if (this.messageattubutslist.length > 0) {
      this.downloadaudits();
    }
  }
  downloadaudits() {
    this.ngxUiLoaderService.start();
    let csvData = '';
    let data = JSON.parse(JSON.stringify(this.messageattubutslist));
    data = data.filter(x => x.ContextId == this.uniqueid);
    data.forEach(ele => {
      delete ele.ContextId;
      delete ele.Id;
      ele.Handler = this.interfacerouteid
    })

    csvData = this.ConvertToCSV(data, ['Searchkey', 'SearchValue', 'Contextid', 'MessageFormat']);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", this.headerRoute + '_' + new Date() + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.ngxUiLoaderService.stop();
  }
  ConvertToCSV(objArray, headerList) {

    let items = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let separator = ',';
    const columns = Object.keys(items[0]).join(separator);
    const body = items.map(item =>
      Object.values(item).join(separator)
    ).join('\n');

    return headerList + '\n' + body;
  }


  copyToClip(data, message: any) {
    if (data != '') {

      copyToClipboard(data);

      this.toastr.success(message + ' Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
}

export interface Messageattributesdata {
  Searchkey: string;
  SearchValue: string;
  ContextId: string;
  Context: string;
  MessageFormat: string;
  Id: bigint;
}
export class FileToUpload {
  fileAsBase64: string = "";
  routeid: string = "";
}
