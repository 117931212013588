import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeAgoPipe } from 'time-ago-pipe';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSelectModule } from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import {MatStepperModule} from '@angular/material/stepper'
import {MatTableModule} from '@angular/material/table'
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule,MatChipRemove } from '@angular/material/chips';
import { CreateComponent } from './create/create.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { TryitoutComponent } from './tryitout/tryitout.component';
import { XmlmappingsComponent } from './xmlmappings/xmlmappings.component';
import { InterfacemappingComponent } from './interfacemapping/interfacemapping.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { HubmessagesComponent } from './hubmessages/hubmessages.component';
import { MatMenuModule } from '@angular/material/menu';
import { TryitoutmainComponent } from './tryitoutmain/tryitoutmain.component';
import { AppConfigService } from './configurations/app-config.service';
import {ProcessorComponent} from './agents/processor/processor.component';
import { AgentlistComponent } from './agents/agentlist/agentlist.component';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RouteNotesComponent } from './route-notes/route-notes.component';
import { MonacoEditorComponent, MonacoEditorLoaderService } from '@materia-ui/ngx-monaco-editor';
import { W700jobslistComponent } from './w700jobslist/w700jobslist.component';
import { SearchFilterPipe } from './w700jobslist/search.pipes';
import { Pagination } from './w700jobslist/pagination.pipes';
import { CreatejobComponent } from './createjob/createjob.component';
import { JobpropertiesComponent } from './jobproperties/jobproperties.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpErrorInterceptor } from './services/HttpInterceptor';
import { VarulesComponent } from './varules/varules.component';
import { FtpfilesComponent } from './ftpfiles/ftpfiles.component';
import { MatRadioModule } from '@angular/material/radio'
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CustomMaterialModule } from './confirm-dialog/custom-material.module';
import { ConditionComponent } from './condition/condition.component';
import { CompendiumComponent } from './compendium/compendium.component';
import { BulkmessagereprocessComponent } from './bulkmessagereprocess/bulkmessagereprocess.component';
import { MappingversionapproveComponent } from './mappingversionapprove/mappingversionapprove.component';
import { MatBadgeModule } from '@angular/material/badge';
import { CompareComponent } from './compare/compare.component';
// import { NgxTextDiffModule } from 'ngx-text-diff';
import { AuthGuard } from './services/auth.guard';
import { TemplateComponent } from './template/template.component';
import { UserroleComponent } from './userrole/userrole.component';
import { AgentComponent } from './agents/agent/agent.component';
import { VbjobsdisplayComponent } from './vbjobsdisplay/vbjobsdisplay.component';
import { VendorlistComponent } from './vendor/vendorlist/vendorlist.component';
import { SavevendorComponent } from './vendor/Savevendor/savevendor.component';
import { MetricshighchartComponent } from './metricshighchart/metricshighchart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MetricpipelinelistComponent } from './metricpipelinelist/metricpipelinelist.component';
import { ErrormessagelistComponent } from './errormessage/errormessagelist.component';
import { ConfigComponent } from './config/config.component';
// import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { BadmessageComponent } from './badmessage/badmessage.component';
import { ProductagentComponent } from './productagent/productagent.component';
import { MessageComponent } from './message/message.component';
import { RequestFormComponent } from './CIIS/request-form/request-form.component';
import { FileViewExplorerComponent } from './file-view-explorer/file-view-explorer.component';
import { StylePaginatorDirective } from './services/paginatorStyleDirective';
import { ContactComponent } from './vendor/contact/contact.component';
import { CacheComponent } from './cache/cache.component';
import { JobComponent } from './job/job.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Routeversion1Component } from './routeversion1/routeversion1.component';
import { MessageattributesComponent } from './messageattributes/messageattributes.component';
import { ContactmoduleComponent } from './contactmodule/contactmodule.component';
import { ManageMenusComponent } from './CIIS/manage-menus/manage-menus.component';
import { LogsComponent } from './logs/logs.component';
import { PatienthistoryComponent } from './patienthistory/patienthistory.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Case360Component } from './case360/case360.component';
import { CaseinfoComponent } from './case360/caseinfo/caseinfo.component';
import { CasemessageComponent } from './case360/casemessage/casemessage.component';
import { CasespecimenComponent } from './case360/casespecimen/casespecimen.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { OtherinfoComponent } from './case360/otherinfo/otherinfo.component';
import { MailComponent } from './mail/mail.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { InterfaceComponent } from './CIIS/interface/interface.component';
import { CaseauditComponent } from './case360/caseaudit/caseaudit.component';
import { DataValidationComponent } from './Model/data-validation/data-validation.component';
import { TripFilterPipe } from '../Utility/tripfilter.pipe';
import { NewauditComponent } from './newaudit/newaudit.component';
import { CasehistoryComponent } from './case360/casehistory/casehistory.component';
import { ConnectivityComponent } from './CIIS/connectivity/connectivity.component';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';
import { ConsolidateviewfileComponent } from './consolidateviewfile/consolidateviewfile.component';
import { Hl7viewerComponent } from './hl7viewer/hl7viewer.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
//import { Clipboard } from '@angular/cdk/clipboard';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { NonvitalcoreComponent } from './nonvitalcore/nonvitalcore.component';
import { IntegrationmappingComponent } from './integrationmapping/integrationmapping.component';
import { PipeLineDataExtractComponent } from './pipelinedataextract/pipelinedataextract.component';
import { PaginationcomponentComponent } from './paginationcomponent/paginationcomponent.component';
import { VarulestooltipComponent } from './labadmininterfaces/varulestooltip/varulestooltip.component';
import { LabadmininterfacesComponent } from './labadmininterfaces/labadmininterfaces.component';
import { DashboardComponent } from './labadmininterfaces/dashboard/dashboard.component';
import { InterfacelistComponent } from './labadmininterfaces/interfacelist/interfacelist.component';
import { MessagelistComponent } from './labadmininterfaces/messagelist/messagelist.component';
import { InterfacemessagecountheaderComponent } from './labadmininterfaces/interfacemessagecountheader/interfacemessagecountheader.component';
import { VitalhublabadminComponent } from './labadmininterfaces/vitalhublabadmin/vitalhublabadmin.component';
import { DocumentsComponent } from './documents/documents.component';
//import { Clipboard } from '@angular/cdk/clipboard';


export function initializeApp(appConfigService: AppConfigService) {
  return () => appConfigService.load()
}
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  //bgsColor: 'red',
  //bgsPosition: POSITION.bottomCenter,
  bgsSize: 50,
  bgsType: SPINNER.threeStrings,
  fgsType: SPINNER.threeStrings,
  fgsSize: 80,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar  direction
  pbThickness: 5, // progress bar thicknesss
  overlayColor: 'rgba(20,20,20,.6)'
};
const monacoConfig: NgxMonacoEditorConfig = {
  defaultOptions: { scrollBeyondLastLine: false }
}
@NgModule({
    declarations: [
        AppComponent,
        CreateComponent,
        LoginComponent,
        TryitoutComponent,
        XmlmappingsComponent,
        InterfacemappingComponent,
        HomepageComponent,
        HubmessagesComponent,
        TryitoutmainComponent,
        ProcessorComponent,
        AgentlistComponent,
        RouteNotesComponent,
        W700jobslistComponent,
        SearchFilterPipe,
        Pagination,
        CreatejobComponent,
        JobpropertiesComponent,
        VarulesComponent,
        FtpfilesComponent,
        ConfirmDialogComponent,
        ConditionComponent,
        CompendiumComponent,
        BulkmessagereprocessComponent,
        MappingversionapproveComponent,
        CompareComponent,
        TemplateComponent,
        UserroleComponent,
        AgentComponent,
        VbjobsdisplayComponent,
        VendorlistComponent,
        SavevendorComponent,
        MetricshighchartComponent,
        MetricpipelinelistComponent,
        ErrormessagelistComponent,
        ConfigComponent,
        BadmessageComponent,
        ProductagentComponent,
        CacheComponent,
        JobComponent,
        RequestFormComponent,
        MessageComponent,
        FileViewExplorerComponent,
        ContactComponent,
        StylePaginatorDirective,
        Routeversion1Component,
        MessageattributesComponent,
        ContactmoduleComponent,
        ManageMenusComponent,
        LogsComponent,
        PatienthistoryComponent,
        Case360Component,
        CaseinfoComponent,
        CasemessageComponent,
        CasespecimenComponent,
        OtherinfoComponent,
        MailComponent,
        DiagnosisComponent,
        InterfaceComponent,
        CaseauditComponent,
        DataValidationComponent,
        TripFilterPipe,
        NewauditComponent,
        CasehistoryComponent,
        ConnectivityComponent,
        ConsolidateviewfileComponent,
        Hl7viewerComponent,
        NonvitalcoreComponent,
        IntegrationmappingComponent,
    PipeLineDataExtractComponent,
    PaginationcomponentComponent,
    VarulestooltipComponent,
    LabadmininterfacesComponent,
    DashboardComponent,
    InterfacelistComponent,
    MessagelistComponent,
    InterfacemessagecountheaderComponent,
    VitalhublabadminComponent,
    DocumentsComponent
    ],
    imports: [
      HighchartsChartModule,
        ClipboardModule,
        RouterModule,
        CommonModule,
        DragDropModule,
        AngularEditorModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatStepperModule,
        MatTabsModule,
        MatBadgeModule,
        MatNativeDateModule,
        MatDatepickerModule,
        NgbModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatInputModule,
        MatCheckboxModule,
        MatChipsModule,
        HttpClientModule,
        AutocompleteLibModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        AppRoutingModule,
        MatTableModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderRouterModule,
        MatListModule,
        MatMenuModule,
        MonacoEditorModule.forRoot(monacoConfig),
        ToastrModule.forRoot({
            timeOut: 4000,
            closeButton: true,
            progressBar: true,
            countDuplicates: true
        }),
        MatExpansionModule,
        MatTreeModule,
        PdfViewerModule,
        MatRadioModule,
        CustomMaterialModule
    ],
    providers: [AuthGuard, DatePipe, MatNativeDateModule, MatDatepickerModule, NgbActiveModal, NgbModal, MonacoEditorModule, MonacoEditorLoaderService,
        AppConfigService, ClipboardService,
        TripFilterPipe,
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) { }
  ngDoBootstrap() {

  }
}

export interface AlertSettings {
  overlay?: boolean;
  overlayClickToClose?: boolean;
  showCloseButton?: boolean;
  duration?: number;
}
